import {IconButton} from 'components/IconButton'
import {ReactComponent as CollapseIcon} from 'icons/collapse.svg'
import {ReactComponent as ExpandIcon} from 'icons/expand.svg'
import React, {useEffect, useState} from 'react'

type Props = {
  toggle?: () => void
  expanded: boolean
  className?: string
}

export const ExpandButton = ({toggle, expanded, className}: Props) => {
  return (
    <IconButton
      icon={expanded ? CollapseIcon : ExpandIcon}
      onClick={toggle ? () => toggle() : undefined}
      className={className}
    />
  )
}

ExpandButton.useExpandButton = (initiallyExpanded: boolean, dependencies: any[] = []) => {
  const [expanded, setExpanded] = useState<boolean>(initiallyExpanded)

  useEffect(() => {
    setExpanded(initiallyExpanded)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies)

  const toggle = () => setExpanded((prev) => !prev)
  return {expanded, toggle}
}
