import {CalendarDate, CalendarMonth} from 'components/Calendars/types'
import {State} from '../types'

export const changeCalendarMonth = (state: State, calendarMonth: CalendarMonth) => {
  return {
    ...state,
    calendarMonth,
    tourDate: null,
    availabilityId: null,
  }
}

export const changeAvailabilityId = (state: State, availabilityId: string | null) => {
  return {
    ...state,
    availabilityId,
  }
}

export const changeTourDate = (state: State, tourDate: CalendarDate | null) => {
  return {
    ...state,
    tourDate,
    availabilityId: null,
  }
}
