import {Location} from '@remix-run/router'
import {useApi} from 'api'
import {Basic} from 'commonTypes'
import moment from 'moment'
import {useInput} from 'providers/InputProvider'
import {useOptionalCart, useOrderIntentId} from 'providers/OrderIntentProvider/hooks'
import {useMutation} from 'react-query'
import {useLocation} from 'react-router-dom'
import {getActualTourCode} from 'utils/getActualTourCode'
import {Card, SaveParams} from './types'

type LocationParams = {
  tourCode?: string
  cartItemId?: string
}

const parseLocation = (location: Location): LocationParams => {
  const params = new URLSearchParams(location.search)
  const tourCode = params.get('tour_code') || undefined
  const cartItemId = params.get('cart_item_id') || undefined
  return {tourCode, cartItemId}
}

const addBooking = (tourCode: string, orderIntentId: string): Card[] => {
  const now = moment()
  return [
    {
      booked: false,
      expanded: true,
      tourCode,
      initialCalendarMonth: {month: now.month(), year: now.year()},
      initialTourDate: null,
      useSaveMutation() {
        const api = useApi()
        return useMutation(async ({availabilityId}: SaveParams) => {
          return api.addCartItem({orderIntentId, availabilityId, tourCode})
        })
      },
    },
  ]
}

const editBooking = (cart: Basic.ExtendedCart, orderIntentId: string, cartItemId?: string): Card[] => {
  return cart.items.map((cartItem) => {
    const availability = moment(cartItem.availabilityId)
    return {
      booked: true,
      tourCode: getActualTourCode(cartItem),
      expanded: cartItem.id === cartItemId,
      availabilityId: cartItem.availabilityId,
      initialTourDate: {date: availability.date(), month: availability.month(), year: availability.year()},
      initialCalendarMonth: {month: availability.month(), year: availability.year()},
      useSaveMutation() {
        const api = useApi()
        return useMutation(({availabilityId}: SaveParams) => {
          return api.setCartItemAvailabilityId({orderIntentId, availabilityId, cartItemId: cartItem.id})
        })
      },
    }
  })
}

export const useCards = (): Card[] => {
  const orderIntentId = useOrderIntentId()
  const cart = useOptionalCart()
  const location = useLocation()
  const input = useInput()
  const {tourCode, cartItemId} = parseLocation(location)

  if (tourCode && cartItemId) {
    throw new Error(`Unexpected both 'tourCode' and 'cartItemId' parameters`)
  }

  if (tourCode) {
    return addBooking(tourCode, orderIntentId)
  }

  if (cartItemId) {
    if (!cart) {
      throw new Error('Cart is not defined')
    }
    return editBooking(cart, orderIntentId, cartItemId)
  }

  if (cart && cart.items.length > 0) {
    return editBooking(cart, orderIntentId, cartItemId)
  }

  return addBooking(input.tourCode, orderIntentId)
}
