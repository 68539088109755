import {Basic} from 'commonTypes'
import {Stepper} from 'components/Stepper'
import {useSettings} from 'providers/AppStateProvider/hooks'
import {useFarthestStepIndex, useStepHistoryPusher} from 'providers/StepHistoryProvider/hooks'
import {useEffect, useMemo} from 'react'
import {Outlet, useLocation} from 'react-router-dom'

const steps = [
  {code: 'unit_items', pathname: '/steps/unit_items', title: 'Travelers'},
  {code: 'booking_slot', pathname: '/steps/booking_slot', title: 'Timeslot'},
  {code: 'addons', pathname: '/steps/addons', title: 'More tours'},
  {code: 'upgrades', pathname: '/steps/upgrades', title: 'Upgrades'},
  {code: 'contact', pathname: '/steps/contact', title: 'Contacts'},
  {code: 'travelers', pathname: '/steps/travelers', title: 'Passes'},
  {code: 'payment', pathname: '/steps/payment', title: 'Payment'},
]

const getSteps = (settings: Basic.Settings) => {
  let resultSteps = steps

  if (!settings.thereAreTourUpgrades) {
    resultSteps = resultSteps.filter(({code}) => code !== 'upgrades')
  }

  return resultSteps
}

export const Steps = () => {
  const settings = useSettings()
  const location = useLocation()
  const pushStep = useStepHistoryPusher()
  const farthestStepIndex = useFarthestStepIndex()

  const steps = useMemo(() => getSteps(settings), [settings])
  const stepIndex = steps.findIndex((step) => step.pathname === location.pathname)

  useEffect(() => {
    if (stepIndex >= 0) {
      pushStep(location, stepIndex)
    }
  }, [location, pushStep, stepIndex])

  const extendedSteps = steps.map((step, index) => {
    return {
      ...step,
      active: index <= farthestStepIndex,
    }
  })

  return (
    <div className="flex flex-col h-full">
      <Stepper steps={extendedSteps} className="mt-6" />
      <Outlet />
    </div>
  )
}
