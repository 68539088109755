import {BookingSlot} from 'pages/BookingSlot/BookingSlot'
import {Checkout} from 'pages/Checkout'
import {Contact} from 'pages/Contact'
import {Result} from 'pages/Result'
import {Timeout} from 'pages/Timeout'
import {TourAddons} from 'pages/TourAddons/TourAddons'
import {TourUpgrades} from 'pages/TourUpgrades'
import {Travelers} from 'pages/Travelers'
import {UnitItems} from 'pages/UnitItems/UnitItems'
import {useHistoryStepPath} from 'providers/StepHistoryProvider/hooks'
import {Navigate, Route, Routes, useLocation} from 'react-router-dom'
import {Steps} from './Steps'

export const Routing = () => {
  const location = useLocation()
  const pathname = useHistoryStepPath(-1)

  if (location.pathname === '/') {
    const redirect = pathname ? pathname : `/steps/unit_items`
    return <Navigate to={redirect} />
  }

  return (
    <Routes>
      <Route path="/steps" element={<Steps />}>
        <Route path="unit_items" element={<UnitItems />} />
        <Route path="booking_slot" element={<BookingSlot />} />
        <Route path="addons" element={<TourAddons />} />
        <Route path="upgrades" element={<TourUpgrades />} />
        <Route path="contact" element={<Contact />} />
        <Route path="travelers" element={<Travelers />} />
        <Route path="payment" element={<Checkout />} />
      </Route>
      <Route path="/timeout" element={<Timeout />} />
      <Route path="/result" element={<Result />} />
    </Routes>
  )
}
