import {Button} from 'components/Button'
import {Frame} from 'components/Frame'
import {TourDetails} from 'components/TourDetails'
import {useTour} from 'providers/AppStateProvider/hooks'
import {useState} from 'react'
import {getTourImageSrc} from 'utils/getTourImageSrc'
import {AddTour} from './AddTour'

type Props = {
  tourCode: string
}

export const TourAddonCard = ({tourCode}: Props) => {
  const [expanded, setExpanded] = useState(false)
  const tour = useTour(tourCode)

  return (
    <Frame imageSrc={getTourImageSrc(tour.images.cover)} imageAlt={tour.product.name} className="p-6">
      <TourDetails tourCode={tourCode} className="mb-4" largeMainName showTags showDuration onlyOption />
      {!expanded && <Button.Standard text="Check Availability" expand outline onClick={() => setExpanded(true)} />}
      {expanded && <AddTour tour={tour} />}
    </Frame>
  )
}
