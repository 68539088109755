import {Text} from 'components/Text'
import {RadioInputField} from 'components/fields/RadioInputField'
import {StandardInputField} from 'components/fields/StandardInputField'
import {useDietaryRestrictionsNecessity} from 'hooks/useDietaryRestrictionsNecessery'

type Props = {
  index: number
}

const parseAge = (value: any) => {
  if (value === '') {
    return undefined
  }
  return parseInt(value, 10)
}

export const Traveler = ({index}: Props) => {
  const dietaryRestrictionsNecessery = useDietaryRestrictionsNecessity()

  return (
    <div className="flex flex-col">
      <Text.H4>Traveler {index + 1}</Text.H4>
      <StandardInputField
        name={`travelers[${index}].age`}
        type="number"
        label="Age"
        className="mt-2"
        min={0}
        max={100}
        parse={parseAge}
        autoFocus={index === 0}
      />
      <StandardInputField
        name={`travelers[${index}].pass`}
        type="text"
        label="Pass number (optional)"
        className="mt-4"
        placeholder="Enter your 12-digit pass number"
      />
      {dietaryRestrictionsNecessery && (
        <div className="flex flex-col gap-2 mt-4">
          <Text>Any dietary restrictions?</Text>
          <div className="grid grid-cols-2 gap-y-2">
            <RadioInputField
              name={`travelers[${index}].dietaryRestrictions`}
              label="None"
              value="none"
              id={`travelers[${index}].dietaryRestrictions.none`}
            />
            <RadioInputField
              name={`travelers[${index}].dietaryRestrictions`}
              label="Lactosefree"
              value="lactosefree"
              id={`travelers[${index}].dietaryRestrictions.lactosefree`}
            />
            <RadioInputField
              name={`travelers[${index}].dietaryRestrictions`}
              label="Vegeterian"
              value="vegetarian"
              id={`travelers[${index}].dietaryRestrictions.vegetarian`}
            />
            <RadioInputField
              name={`travelers[${index}].dietaryRestrictions`}
              label="Glutenfree"
              value="glutenfree"
              id={`travelers[${index}].dietaryRestrictions.glutenfree`}
            />
            <RadioInputField
              name={`travelers[${index}].dietaryRestrictions`}
              label="Vegan"
              value="vegan"
              id={`travelers[${index}].dietaryRestrictions.vegan`}
            />
          </div>
        </div>
      )}
    </div>
  )
}
