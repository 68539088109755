import {useContext} from 'react'
import {LocalStorageContext} from './provider'

export const useLocalStorage = () => {
  const context = useContext(LocalStorageContext)

  if (!context) {
    throw new Error('Local storage context is not initialized')
  }

  return context
}

export const useOrderIntentInfo = () => {
  const localStorage = useLocalStorage()
  return localStorage.orderIntentInfo
}

export const useStepHistory = () => {
  const localStorage = useLocalStorage()
  return localStorage.stepHistory || []
}

export const useOrderIntentInfoSetter = () => {
  const localStorage = useLocalStorage()
  return localStorage.setOrderIntentInfo
}

export const useStepHistorySetter = () => {
  const localStorage = useLocalStorage()
  return localStorage.setStepHistory
}

export const useLastActionAtSetter = () => {
  const localStorage = useLocalStorage()
  return localStorage.setLastActionAt
}
