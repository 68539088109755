import {formatMoney} from '@exp1/common-utils'
import {Text} from 'components/Text'
import {Timer} from 'components/Timer'
import {useSettings} from 'providers/AppStateProvider/hooks'
import {useOptionalCart, useOptionalUnitCounters, useOptionalVentrataOrder} from 'providers/OrderIntentProvider/hooks'
import {ExpandButton} from '../ExpandButton'
import {CartUnits} from './CartUnits'
import {OrderComponent} from './OrderComponent'

type Props =
  | {
      collapsable: false
    }
  | {
      collapsable: true
      toggle: () => void
      expanded: boolean
    }

export const CartHeader = (props: Props) => {
  const ventrataOrder = useOptionalVentrataOrder()
  const unitCounters = useOptionalUnitCounters()
  const cart = useOptionalCart()
  const {currency} = useSettings()
  const totalAmount = cart ? cart.pricing.displayed.total : 0

  return (
    <OrderComponent className="flex flex-col gap-2">
      <div className="flex flex-row justify-between flex-wrap">
        <div className="flex gap-2 items-center">
          <Text.H4 className="whitespace-nowrap">Order details</Text.H4>
          {props.collapsable && <ExpandButton expanded={props.expanded} toggle={props.toggle} />}
        </div>
        {ventrataOrder && <Timer utcExpiresAt={ventrataOrder.utcExpiresAt} />}
      </div>
      <div className="flex flex-row justify-between flex-wrap">
        {unitCounters ? <CartUnits /> : <Text>No bookings</Text>}
        <Text className="justify-self-end">{formatMoney(totalAmount, currency, true)}</Text>
      </div>
    </OrderComponent>
  )
}
