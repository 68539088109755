import {Basic} from 'commonTypes'

export const getActualBooking = (cartItem: Basic.CartItem) => {
  return cartItem.upgraded ? cartItem.upgraded : cartItem.original
}

export const getActualExtendedBooking = (cartItem: Basic.ExtendedCartItem) => {
  return cartItem.upgraded ? cartItem.upgraded : cartItem.original
}

export const getActualTourCode = (cartItem: Basic.CartItem | Basic.ExtendedCartItem) => {
  return getActualBooking(cartItem).tour.code
}
