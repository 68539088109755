import {formatMoney} from '@exp1/common-utils'
import cx from 'classnames'
import {Basic} from 'commonTypes'
import {Button} from 'components/Button'
import {SmallTourImage} from 'components/SmallTourImage'
import {TourDetails} from 'components/TourDetails'
import {useResetCartItemMutation} from 'hooks/mutations/useResetCartItemMutation'
import {useUpgradeCartItemMutation} from 'hooks/mutations/useUpgradeCartItemMutation'
import {useSettings} from 'providers/AppStateProvider/hooks'
import {useOrderIntentId, useOrderIntentSetter} from 'providers/OrderIntentProvider/hooks'
import {useChangeCartEventSender} from 'providers/WidgetGTMProvider'
import {CustomErrorMessage} from './CustomErrorMessage'
import {PriceDifferences} from './PriceDifferences'

type Props = {
  tourCode: string
  priceDifferences: Basic.UnitPricing
  amountDifference: number
  cartItemId: string
  images: Basic.TourImages
  upgraded: boolean
  edited: boolean
  className?: string
  description: string
}

export const UpgradeTourCard = ({
  tourCode,
  priceDifferences: priceDifference,
  amountDifference,
  cartItemId,
  images,
  upgraded,
  edited,
  className,
  description,
}: Props) => {
  const {currency} = useSettings()
  const orderIntentId = useOrderIntentId()
  const setOrderIntent = useOrderIntentSetter()
  const upgradeTourMutation = useUpgradeCartItemMutation()
  const cancelTourUpgradeMutation = useResetCartItemMutation()
  const sendChangeCartEvent = useChangeCartEventSender()

  const error = upgradeTourMutation.error || cancelTourUpgradeMutation.error

  const upgrade = () => {
    upgradeTourMutation.mutate(
      {
        tourCode,
        cartItemId,
        orderIntentId,
      },
      {
        async onSuccess({orderIntent, affectedCartItem}) {
          await sendChangeCartEvent(affectedCartItem, 'add')
          setOrderIntent(orderIntent)
        },
      }
    )
  }

  const cancel = () => {
    cancelTourUpgradeMutation.mutate(
      {
        cartItemId,
        orderIntentId,
      },
      {
        async onSuccess({orderIntent, affectedCartItem}) {
          await sendChangeCartEvent(affectedCartItem, 'remove')
          setOrderIntent(orderIntent)
        },
      }
    )
  }

  return (
    <div className="relative">
      <div
        className={cx(
          'z-10 relative top-[-1px] left-[-1px] w-[calc(100%+2px)] h-[calc(100%+2px)]',
          {'border-2 !border-french-blue': edited && upgraded},
          className
        )}
      >
        <div className=" flex flex-col sm:flex-row gap-4 justify-between">
          <div className="flex gap-4">
            <SmallTourImage tourImage={images.logo} className="shrink-0" />
            <TourDetails tourCode={tourCode} description={description} onlyOption />
          </div>
          <div className="hidden sm:flex justify-start flex-col gap-2 items-center">
            {upgraded ? (
              <Button.Standard
                text="Remove"
                onClick={cancel}
                outline
                className="min-w-[150px] px-0 !h-10 !rounded-full"
              />
            ) : (
              <Button.Standard
                text={`Add\u{00A0}for\u{00A0}${formatMoney(amountDifference, currency)}`}
                onClick={upgrade}
                className="min-w-[150px] px-0 !h-10 !rounded-full"
              />
            )}
            <PriceDifferences priceDifferences={priceDifference} />
          </div>
          <div className="sm:hidden flex justify-start flex-col gap-2 items-center">
            <div className="flex gap-4 w-full">
              <div className="w-16 shrink-0" />
              <PriceDifferences priceDifferences={priceDifference} />
            </div>
            {upgraded ? (
              <Button.Standard text="Remove" onClick={cancel} outline className="w-full px-0 !h-10 !rounded-full" />
            ) : (
              <Button.Standard
                text={`Add\u{00A0}for\u{00A0}${formatMoney(amountDifference, currency)}`}
                onClick={upgrade}
                className="w-full px-0 !h-10 !rounded-full"
              />
            )}
          </div>
        </div>
        <CustomErrorMessage error={error} />
      </div>
    </div>
  )
}
