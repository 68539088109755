import cx from 'classnames'
import {noop} from 'lodash'
import styles from './styles.module.css'

type Props = {
  checked: boolean
  disabled?: boolean
  onChange?: (checked: boolean) => void
  className?: string
}

export const CheckboxInput = ({checked, disabled, onChange = noop, className}: Props) => {
  return (
    <input
      type="checkbox"
      className={cx(styles.checkbox, className)}
      checked={checked}
      disabled={disabled}
      onChange={(event) => onChange(event.target.checked)}
    />
  )
}
