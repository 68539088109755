import {get} from 'lodash'
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from './tailwind.config'

const twFullConfig = resolveConfig(tailwindConfig)

export const getTailwindColor = (name: string): string => {
  const color = get(twFullConfig, ['theme', 'colors', name])
  if (!color) {
    throw new Error(`Can't find color '${name}'`)
  }
  return color
}
