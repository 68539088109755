import {Basic} from 'commonTypes'
import {Button} from 'components/Button'
import {CloseButton} from 'components/CloseButton'
import {Modal, ModalState} from 'components/Modal'
import {Text} from 'components/Text'
import {ReactComponent as ExclamationIcon} from 'icons/exclamation.svg'

type Props = {
  state: ModalState<Basic.ExtendedTour>
  onClose: () => void
  onRemove: () => void
}

export const RemoveConfirmation = ({state, onClose, onRemove}: Props) => {
  return (
    <Modal
      state={state}
      onClose={onClose}
      content={({payload: tour}: {payload: Basic.ExtendedTour}) => {
        return (
          <div className="flex flex-col gap-6 w-full sm:w-[30rem]">
            <div className="flex justify-between">
              <ExclamationIcon />
              <CloseButton onClick={onClose} />
            </div>
            <div className="flex flex-col gap-2">
              <Text.H2>Remove tour</Text.H2>
              <div className="flex flex-col">
                <Text tag="span">Please confirm that you want to remove </Text>
                <Text.Bold tag="span">{tour.option.name}</Text.Bold>
                <Text tag="span"> tour from your order</Text>
              </div>
            </div>
            <div className="flex gap-4">
              <Button.Danger text={`Yes,\u{00A0}remove`} onClick={onRemove} expand />
              <Button.Secondary text="Cancel" outline onClick={onClose} expand />
            </div>
          </div>
        )
      }}
    />
  )
}

RemoveConfirmation.useModal = Modal.useModal<Basic.ExtendedTour>
