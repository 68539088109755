import {formatMoney} from '@exp1/common-utils'
import {Basic} from 'commonTypes'
import {Text} from 'components/Text'
import {ReactComponent as LightningIcon} from 'icons/lightning.svg'
import {useMandatoryPayment, useSettings} from 'providers/AppStateProvider/hooks'

type Props = {
  mandatoryPayment: Basic.ExtendedCartItemMandatoryPayment
}

export const MandatoryPayment = ({mandatoryPayment}: Props) => {
  const {currency} = useSettings()
  const mandatoryPaymentInfo = useMandatoryPayment(mandatoryPayment.code)

  return (
    <div className="flex flex-col">
      <div className="flex gap-4">
        <div className="w-16 h-1 shrink-0" />
        <div className="flex flex-col">
          <div className="flex tracking-[.04em]">
            <div className="flex gap-2 items-center">
              <LightningIcon />
              <Text fontSize="13px" lineHeight="16px">
                MANDATORY PAYMENT
              </Text>
            </div>
            <Text fontSize="13px" lineHeight="16px" className="whitespace-pre">
              {' '}
              •{' '}
            </Text>
            <Text fontSize="13px" lineHeight="16px">
              {formatMoney(mandatoryPayment.pricing, currency, true)}
            </Text>
          </div>
          <Text.Bold>{mandatoryPaymentInfo.title}</Text.Bold>
        </div>
      </div>
    </div>
  )
}
