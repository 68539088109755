import {CloseButton} from 'components/CloseButton'
import {Modal, ModalState} from 'components/Modal'
import passPreviewSrc from './pass-preview.png'

type Props = {
  state: ModalState<void>
  onClose: () => void
}

export const Instruction = ({state, onClose}: Props) => {
  return (
    <Modal
      state={state}
      onClose={onClose}
      content={() => {
        return (
          <div className="relative">
            <img
              src={passPreviewSrc}
              className="flex flex-col gap-4 w-[266px] p-5"
              alt="pass preview"
              onClick={onClose}
            />
            <CloseButton className="absolute right-0 top-0" onClick={onClose} />
          </div>
        )
      }}
    />
  )
}

Instruction.useModal = Modal.useModal<void>
