import {Basic} from 'commonTypes'
import {OrderComponent} from '../OrderComponent'
import {MandatoryPayment} from './MandatoryPayment'
import {OriginalTour} from './OriginalTour'
import {UpgradedTour} from './UpgradedTour'

type Props = {
  cartItem: Basic.ExtendedCartItem
}

export const CartItem = ({cartItem}: Props) => {
  return (
    <OrderComponent className="flex flex-col gap-4 px-0">
      {!cartItem.upgraded && (
        <>
          <OriginalTour
            tourCode={cartItem.original.tour.code}
            availabilityId={cartItem.availabilityId}
            amount={cartItem.original.pricing.displayed}
            cartItemId={cartItem.id}
          />
          {cartItem.mandatoryPayments.map((mandatoryPayment) => {
            return <MandatoryPayment mandatoryPayment={mandatoryPayment} key={mandatoryPayment.code} />
          })}
        </>
      )}
      {cartItem.upgraded && (
        <>
          <OriginalTour
            tourCode={cartItem.original.tour.code}
            availabilityId={cartItem.availabilityId}
            amount={0}
            cartItemId={cartItem.id}
          />
          <UpgradedTour
            tourCode={cartItem.upgraded.tour.code}
            amount={cartItem.upgraded.pricing.displayed}
            cartItemId={cartItem.id}
          />
          {cartItem.mandatoryPayments.map((mandatoryPayment) => {
            return <MandatoryPayment mandatoryPayment={mandatoryPayment} key={mandatoryPayment.code} />
          })}
        </>
      )}
    </OrderComponent>
  )
}
