import {Basic} from 'commonTypes'
import {Text} from './Text'

type Props = {
  mandatoryPayment: Basic.MandatoryPayment
}

export const MandatoryPaymentCard = ({mandatoryPayment}: Props) => {

  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between items-center">
        <Text.Bold>{mandatoryPayment.title}</Text.Bold>
      </div>
      <div className="flex flex-col gap-2">
        <Text>
          <div dangerouslySetInnerHTML={{__html: mandatoryPayment.description}} />
        </Text>
        {/* <div className="flex flex-col md:flex-row md:gap-4">
          <Text color={getTailwindColor('grey-0')}>{formatMoney(mandatoryPayment.pricing.ADULT, currency)}/adult</Text>
          <Text color={getTailwindColor('grey-0')}>{formatMoney(mandatoryPayment.pricing.CHILD, currency)}/child</Text>
          <Text color={getTailwindColor('grey-0')}>
            {formatMoney(mandatoryPayment.pricing.INFANT, currency)}/infant
          </Text>
        </div> */}
      </div>
    </div>
  )
}
