import {useApi} from 'api'
import {AxiosError} from 'axios'
import {Params, Responses} from 'commonTypes'
import {useQuery} from 'react-query'
import {QueryOptions} from 'types'

export const useGetAllAllowedUpgradesQuery = (
  {orderIntentId}: Params.GetAllAllowedUpgrades,
  options?: QueryOptions
) => {
  const api = useApi()
  return useQuery<Responses.GetAllAllowedUpgrades, AxiosError>(
    ['getAllAllowedUpgrades', orderIntentId],
    () => api.getAllAllowedUpgrades({orderIntentId}),
    {
      ...options,
    }
  )
}
