import {CalendarDate} from 'components/Calendars/types'
import {useReducer} from 'react'
import {changeAvailabilityId, changeCalendarWeek, changeTourDate} from './actions'
import {Action, State} from './types'

const pageStateReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'changeTourDate':
      return changeTourDate(state, action.tourDate)
    case 'changeCalendarWeek':
      return changeCalendarWeek(state, action.calendarWeek)
    case 'changeAvailabilityId':
      return changeAvailabilityId(state, action.availabilityId)
  }
}

export const useCardStateManager = (initialState: State) => {
  const [state, dispatch] = useReducer(pageStateReducer, initialState)

  const changeAvailabilityId = (availabilityId: string | null) => {
    dispatch({
      type: 'changeAvailabilityId',
      availabilityId,
    })
  }

  const changeCalendarWeek = (calendarWeek: CalendarDate) => {
    dispatch({
      type: 'changeCalendarWeek',
      calendarWeek,
    })
  }

  const changeTourDate = (tourDate: CalendarDate | null) => {
    dispatch({
      type: 'changeTourDate',
      tourDate,
    })
  }

  const isCompleted = () => {
    return state.availabilityId !== null
  }

  return {
    state,
    changeCalendarWeek,
    changeAvailabilityId,
    changeTourDate,
    isCompleted,
  }
}
