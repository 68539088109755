import {Entities, UNIT_TYPES_UNION} from '@exp1/ventrata-utils'
import {sumBy} from 'lodash'
import {useReducer} from 'react'
import {minus, plus} from './actions'
import {Action} from './types'

const pageStateReducer = (state: Entities.TypeUnitCounter[], action: Action): Entities.TypeUnitCounter[] => {
  switch (action.type) {
    case 'plus':
      return plus(state, action.unitType)
    case 'minus':
      return minus(state, action.unitType)
  }
}

export const usePageStateManager = (initialUnitCounters: Entities.TypeUnitCounter[]) => {
  const [unitCounters, dispatch] = useReducer(pageStateReducer, initialUnitCounters)

  const plus = (unitType: UNIT_TYPES_UNION) => {
    dispatch({
      type: 'plus',
      unitType,
    })
  }

  const minus = (unitType: UNIT_TYPES_UNION) => {
    dispatch({
      type: 'minus',
      unitType,
    })
  }

  const isCompleted = () => {
    return sumBy(unitCounters, 'quantity') > 0
  }

  return {
    unitCounters,
    plus,
    minus,
    isCompleted,
  }
}
