import {useApi} from 'api'
import {Params} from 'commonTypes'
import {useQuery} from 'react-query'
import {QueryOptions} from 'types'

export const useGetUnitsQuery = ({configCode, tourCode}: Params.GetUnits, options?: QueryOptions) => {
  const api = useApi()
  return useQuery(
    ['getUnits', configCode, tourCode],
    () =>
      api.getUnits({
        configCode,
        tourCode,
      }),
    options
  )
}
