import {Basic} from 'commonTypes'
import {Availabilities} from 'components/Availabilities'
import {Button} from 'components/Button'
import {WeekCalendar} from 'components/Calendars'
import {Delimiter} from 'components/Delimiter'
import {MandatoryPaymentCard} from 'components/MandatoryPaymentCard'
import {Text} from 'components/Text'
import {useTourMandatoryPayments} from 'providers/AppStateProvider/hooks'
import {useCardManager} from './useCardManager'

type Props = {
  tour: Basic.ExtendedTour
}

export const AddTour = ({tour}: Props) => {
  const manager = useCardManager({tourCode: tour.code})
  const tourMandatoryPayments = useTourMandatoryPayments(tour.code)

  if (!manager.data.days) {
    return <Button.Standard className="mt-4" text="Check Availability" expand outline />
  }

  return (
    <div className="flex flex-col gap-4">
      <Text className="mt-4">
        <span dangerouslySetInnerHTML={{__html: tour.option.shortDescription}} />
      </Text>

      {tourMandatoryPayments.length > 0 && (
        <>
          <Delimiter className="my-2" />
          {tourMandatoryPayments.map((mandatoryPayment) => {
            return <MandatoryPaymentCard key={mandatoryPayment.code} mandatoryPayment={mandatoryPayment} />
          })}
        </>
      )}

      <WeekCalendar
        className="mb-4"
        tourDate={manager.state.tourDate}
        calendarWeek={manager.state.calendarWeek}
        onChangeTourDate={manager.changeTourDate}
        onChangeCalendarWeek={manager.changeCalendarWeek}
        days={manager.data.days}
      />

      <Availabilities
        availabilities={manager.data.availabilities}
        selectedAvailabilityId={manager.state.availabilityId}
        onChange={manager.changeAvailabilityId}
      />

      <Button.Standard text="Add to cart" expand outline onClick={manager.save} disabled={!manager.isCompleted()} />
    </div>
  )
}
