import cx from 'classnames'

type Props = {
  alt: string
  src: string
  className?: string
}

export const BaseTourImage = ({src, alt, className}: Props) => {
  return <img className={cx('object-cover', className)} src={src} alt={alt} />
}

export const CardTourImage = ({src, alt, className}: Props) => {
  return (
    <BaseTourImage alt={alt} src={src} className={cx('w-full rounded-t-lg bg-neutral-grey min-h-[256px]', className)} />
  )
}

export const UpgradeTourImage = ({src, alt, className}: Props) => {
  return (
    <BaseTourImage alt={alt} src={src} className={cx('w-full min-h-[228px] rounded-lg bg-neutral-cream', className)} />
  )
}
