import {CalendarDate} from 'components/Calendars/types'
import {State} from './types'

export const changeAvailabilityId = (state: State, availabilityId: string | null) => {
  return {
    ...state,
    availabilityId,
  }
}

export const changeCalendarWeek = (state: State, calendarWeek: CalendarDate) => {
  return {
    ...state,
    calendarWeek,
    tourDate: null,
    availabilityId: null,
  }
}

export const changeTourDate = (state: State, tourDate: CalendarDate | null) => {
  return {
    ...state,
    tourDate,
    availabilityId: null,
  }
}
