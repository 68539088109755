import {useGetResultQuery} from 'hooks/queries/useGetResultQuery'
import {useOrderIntent} from 'providers/OrderIntentProvider/hooks'
import {useCheckoutEventSender} from 'providers/WidgetGTMProvider'

export const usePageManager = () => {
  const orderIntent = useOrderIntent()
  const sendCheckoutEvent = useCheckoutEventSender()

  const getResultQuery = useGetResultQuery(
    {orderIntentId: orderIntent.id},
    {
      onSuccess({orderStatus, redirect}) {
        if (orderStatus !== 'CONFIRMED') {
          setTimeout(getResultQuery.refetch, 1000)
          return
        }

        sendCheckoutEvent(orderIntent)

        if (!redirect) {
          return
        }

        setTimeout(() => {
          if (!redirect) {
            return
          }
          window.parent.location.href = redirect.url
        }, redirect.delaySec * 1000)
      },
    }
  )

  const error = getResultQuery.error

  return {
    error,
    ready: getResultQuery.data && getResultQuery.data.orderStatus === 'CONFIRMED',
  }
}
