import {useTours} from 'providers/AppStateProvider/hooks'
import {useCart} from 'providers/OrderIntentProvider/hooks'
import {getActualTourCode} from 'utils/getActualTourCode'

export const useDietaryRestrictionsNecessity = () => {
  const cart = useCart()
  const tours = useTours()

  return cart.items.some((item) => {
    const tourCode = getActualTourCode(item)
    const tour = tours.find((tour) => tour.code === tourCode)

    if (!tour) {
      throw new Error(`Can't find tour by tour code '${tourCode}'`)
    }

    return tour.product.questionKeys.includes('dietaryRestrictions')
  })
}
