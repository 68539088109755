/** @type {import('tailwindcss').Config} */

module.exports = {
  content: [
    "./src/**/*.{js,jsx,ts,tsx}",
  ],
  theme: {
    extend: {
      screens: {
        xs: '430px',
        sm: '610px',
        md: '730px',
        lg: '960px',
      },
      colors: {
        'french-blue': '#06273F',
        'dark-blue': '#0063D7',
        'neutral-grey-dark': '#929F9A',
        'neutral-grey': '#DDE2E5',
        'grey-5': '#E0E0E0',
        'explorer-orange': '#FF9400',
        'secondary-orange': '#FF7800',
        'brand-red': '#FF1D25',
        'brooklyn-parrots-green': '#9FA15A',
        'input-background': '#F6F5F5',
        'input-border': '#F3F3F3',
        'input-placeholder': '#828282',
        'cart-background': '#F2F2F3',
        'backdrop': '#F2F2F3',
        'neutral-cream': '#FFEACE',
        'grey-0': '#7F7F7F'
      },
      width: {
        'right-side': '388px',
        'left-side': '486px',
      }
    },
  },
  plugins: [],
}
