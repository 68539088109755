import {Basic} from 'commonTypes'
import {ErrorMessage} from 'components/ErrorMessage'
import React, {useContext, useEffect, useState} from 'react'
import {useSearchParams} from 'react-router-dom'

type Props = {
  children: React.ReactNode
}

const useInputParams = () => {
  const [searchParams] = useSearchParams()

  return {
    tourCode: searchParams.get('tour_code'),
    configCode: searchParams.get('config_code'),
    siteUrl: searchParams.get('site_url'),
    orderIntentId: searchParams.get('order_intent_id') || undefined,
  }
}

export const InputContext = React.createContext<Basic.Input | null>(null)

export const InputProvider = ({children}: Props) => {
  const {tourCode, configCode, siteUrl, orderIntentId} = useInputParams()

  const [input, setInput] = useState<Basic.Input | null>(null)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    if (!input && configCode && siteUrl && tourCode) {
      return setInput({tourCode, configCode, siteUrl, orderIntentId})
    }
    if (!input) {
      setError(`Incorrect input parameters`)
    }
  }, [configCode, siteUrl, input, tourCode, orderIntentId])

  if (error) {
    return <ErrorMessage error={error} />
  }

  if (!input) {
    return null
  }

  return <InputContext.Provider value={input}>{children}</InputContext.Provider>
}

export const useInput = () => {
  const input = useContext(InputContext)

  if (!input) {
    throw new Error('Input is null')
  }

  return input
}
