import cx from 'classnames'
import {Text} from 'components/Text'

type Props = {
  disabled?: boolean
  children: string
  fontSize?: string
  lineHeight?: string
}

export const LinkText = ({children, disabled, fontSize, lineHeight}: Props) => {
  return (
    <Text
      color={null}
      className={cx('text-dark-blue hover:opacity-80 cursor-pointer', {'opacity-50 pointer-events-none': disabled})}
      tag="span"
      fontSize={fontSize}
      lineHeight={lineHeight}
    >
      {children}
    </Text>
  )
}
