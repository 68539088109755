import cx from 'classnames'
import {CheckboxInput} from 'components/inputs/CheckboxInput'
import {ReactNode, useState} from 'react'

type Props = {
  active: boolean
  accepted: boolean
  onChange: (value: boolean) => void
  className?: string
  activeState: ReactNode
  inactiveAcceptedState: ReactNode
  inactiveDeclinedState?: ReactNode
}

export const Acceptance = ({
  active,
  accepted,
  onChange,
  className,
  activeState,
  inactiveAcceptedState,
  inactiveDeclinedState,
}: Props) => {
  return (
    <div className={cx('flex gap-2', className)}>
      {active && <CheckboxInput checked={accepted} onChange={onChange} className="mt-[3px]" />}
      <div>
        {active ? activeState : accepted ? inactiveAcceptedState : inactiveDeclinedState || inactiveAcceptedState}
      </div>
    </div>
  )
}

Acceptance.useManager = (initialState: boolean): [boolean, () => void] => {
  const [accepted, setAccepted] = useState(initialState)
  const toggle = () => setAccepted((previousValue) => !previousValue)
  return [accepted, toggle]
}
