import {GTMProvider, useGTMDispatch} from '@elgorditosalsero/react-gtm-hook'
import {Entities} from '@exp1/ventrata-utils'
import {Basic} from 'commonTypes'
import {useSettings, useTour, useTours} from 'providers/AppStateProvider/hooks'
import {useInput} from 'providers/InputProvider'
import {useUnitCounters} from 'providers/OrderIntentProvider/hooks'
import {ReactNode} from 'react'
import {countGuests} from 'utils/countGuests'
import {getActualTourCode} from 'utils/getActualTourCode'
import {getTourByCode} from 'utils/getTourByCode'

const getLocationName = (locationCode: string) => {
  switch (locationCode) {
    case 'Par':
      return 'Paris'
    case 'Bar':
      return 'Barcelona'
    case 'NYC':
      return 'New York'
    case 'SF':
      return 'San Francisco'
    case 'LA':
      return 'Los Angeles'
    default:
      return 'Unknown'
  }
}

type DataLayer = {
  event: string
}

type DataLayerEvent = DataLayer & {
  [key: string]: any
}

type WidgetGTMProviderProps = {
  children: ReactNode
  dataLayer?: DataLayerEvent
}

export const WidgetGTMProvider = ({children, dataLayer}: WidgetGTMProviderProps) => {
  const {gtmContainerId} = useSettings()
  const state = {
    id: gtmContainerId,
    dataLayer,
  }
  return <GTMProvider state={state}>{children}</GTMProvider>
}

type CheckoutGTMProviderProps = {
  children: ReactNode
  stepName: string
}

export const CheckoutGTMProvider = ({children, stepName}: CheckoutGTMProviderProps) => {
  const {tourCode} = useInput()
  const tour = useTour(tourCode)
  return (
    <WidgetGTMProvider
      dataLayer={{
        event: 'pass_widget_checkout',
        step_number: stepName,
        selected_tour: tour.option.name,
      }}
    >
      {children}
    </WidgetGTMProvider>
  )
}

const getPrefixedTagValue = (tags: string[] | undefined, prefix: string) => {
  const tagStart = `${prefix}:`
  const value = (tags || []).find((tag) => tag.startsWith(tagStart))
  return value ? value.replace(tagStart, '') : ''
}

const getCartItemInfo = (
  cartItem: Basic.ExtendedCartItem,
  tour: Basic.ExtendedTour,
  unitCounters: Entities.TypeUnitCounter[],
  passTourCode: string,
  locationName: string
) => {
  const price = 0
  const brand = getPrefixedTagValue(tour.product.tags, 'brand')
  const type = getPrefixedTagValue(tour.product.tags, 'type')
  return {
    item_id: tour.product.id,
    item_name: tour.product.name,
    price,
    quantity: countGuests(unitCounters),
    item_brand: brand,
    item_category: 'Group',
    item_category2: locationName,
    item_category3: type,
    pass_tour: tour.code === passTourCode ? '1' : '0',
    upgrade: cartItem.upgraded ? '1' : '0',
    tour_destination: locationName,
  }
}

export const useCustomGTMEventSender = () => {
  const sendDataToGTM = useGTMDispatch()
  return async (event: DataLayerEvent) => {
    sendDataToGTM(event)
    return new Promise((resolve) => setTimeout(resolve, 500))
  }
}

export const useChangeCartEventSender = () => {
  const tours = useTours()
  const unitCounters = useUnitCounters()
  const {currency, locationCode} = useSettings()
  const sendDataToGTM = useCustomGTMEventSender()
  const {tourCode: passTourCode} = useInput()
  const locationName = getLocationName(locationCode)

  return (cartItem: Basic.ExtendedCartItem, action: 'add' | 'remove') => {
    const tourCode = getActualTourCode(cartItem)
    const tour = getTourByCode(tours, tourCode)
    const cartItemInfo = getCartItemInfo(cartItem, tour, unitCounters, passTourCode, locationName)
    return sendDataToGTM({
      Label: tour.product.name,
      event: action === 'add' ? 'add_to_cart' : 'remove_from_cart',
      ecommerce: {
        currency,
        value: cartItemInfo.price * cartItemInfo.quantity,
        items: [cartItemInfo],
      },
    })
  }
}

export const useCheckoutEventSender = () => {
  const tours = useTours()
  const unitCounters = useUnitCounters()
  const sendDataToGTM = useCustomGTMEventSender()
  const {tourCode: passTourCode} = useInput()
  const {currency, locationCode} = useSettings()
  const locationName = getLocationName(locationCode)

  return (orderIntent: Basic.OrderIntent) => {
    if (!orderIntent.extendedCart) {
      throw new Error(`Cart is missing in order intent`)
    }

    if (!orderIntent.payment) {
      throw new Error(`Payment is missing in order intent`)
    }

    const cartItemInfos = orderIntent.extendedCart.items.map((cartItem) => {
      const tourCode = getActualTourCode(cartItem)
      const tour = getTourByCode(tours, tourCode)
      return getCartItemInfo(cartItem, tour, unitCounters, passTourCode, locationName)
    })

    return sendDataToGTM({
      event: 'purchase',
      ecommerce: {
        currency,
        value: cartItemInfos.reduce((result, {quantity, price}) => result + quantity * price, 0),
        tax: 0,
        shipping: 0,
        transaction_id: orderIntent.ventrataOrder?.supplierReference,
        coupon: '',
        payment_type: orderIntent.payment.method,
        purchase_with_upgrade: orderIntent.extendedCart.items.find((cartItem) => cartItem.upgraded) ? '1' : '0',
        purchase_with_child: unitCounters.find(({type, quantity}) => type === 'CHILD' && quantity > 0) ? '1' : '0',
        purchase_with_infant: unitCounters.find(({type, quantity}) => type === 'INFANT' && quantity > 0) ? '1' : '0',
        items: cartItemInfos,
      },
    })
  }
}
