import { Entities } from '@exp1/ventrata-utils'
import { Basic } from 'commonTypes'
import { useSetUnitCountersMutation } from 'hooks/mutations/useSetUnitCountersMutation'
import { useToursMandatoryPayments, useUnits } from 'providers/AppStateProvider/hooks'
import { useInput } from 'providers/InputProvider'
import { useOptionalCart, useOptionalUnitCounters, useOrderIntentId, useOrderIntentSetter } from 'providers/OrderIntentProvider/hooks'
import { useIsReturnStepVisit } from 'providers/StepHistoryProvider/hooks'
import { useNavigate } from 'react-router-dom'
import { usePageStateManager } from './pageStateManager'

const getInitialCounters = (units: Basic.Unit[], unitCounters: Entities.TypeUnitCounter[] | undefined) => {
  if (unitCounters) {
    return unitCounters
  }

  const firstUnit = units[0]
  return [{ type: firstUnit.type, quantity: 1 }, ...units.slice(1).map((unit) => ({ type: unit.type, quantity: 0 }))]
}

const useTotalPricing = () => {
  const cart = useOptionalCart()
  const { tourCode } = useInput()

  const tourCodes = cart ? cart.items.map((cartItem) => cartItem.original.tour.code) : [tourCode]

  const mandatoryPyaments = useToursMandatoryPayments(tourCodes)

  return mandatoryPyaments.reduce((result, mandatoryPayment) => {
    return {
      ADULT: result.ADULT + mandatoryPayment.pricing.ADULT,
      CHILD: result.CHILD + mandatoryPayment.pricing.CHILD,
      INFANT: result.INFANT + mandatoryPayment.pricing.INFANT,
    }
  }, {
    ADULT: 0,
    CHILD: 0,
    INFANT: 0,
  })
}

export const usePageManager = () => {
  const navigate = useNavigate()
  const units = useUnits()
  const unitCounters = useOptionalUnitCounters()
  const initialUnitCounters = getInitialCounters(units, unitCounters)
  const orderIntentId = useOrderIntentId()
  const manager = usePageStateManager(initialUnitCounters)
  const setOrderIntent = useOrderIntentSetter()
  const setUnitCountersMutation = useSetUnitCountersMutation()
  const { tourCode } = useInput()
  const isReturnStepVisit = useIsReturnStepVisit()
  const cart = useOptionalCart()

  const setUnitCounters = () => {
    setUnitCountersMutation.mutate(
      {
        orderIntentId,
        unitCounters: manager.unitCounters,
      },
      {
        onSuccess({ orderIntent }) {
          setOrderIntent(orderIntent)
          navigate(`/steps/booking_slot?tour_code=${tourCode}`)
        },
      }
    )
  }

  const error = setUnitCountersMutation.error

  const displayTourCard = !(isReturnStepVisit && cart && cart.items.length > 1)

  const totalPricing = useTotalPricing()

  const data = {
    units,
    tourCode,
    totalPricing,
  }

  return {
    unitCounters: manager.unitCounters,
    error,
    data,
    setUnitCounters,
    plus: manager.plus,
    minus: manager.minus,
    isCompleted: manager.isCompleted,
    isEdit: isReturnStepVisit,
    displayTourCard,
  }
}
