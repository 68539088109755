import {formatMoney, pluralize} from '@exp1/common-utils'
import {PaymentElement} from '@stripe/react-stripe-js'
import * as stripeJs from '@stripe/stripe-js'
import {Button} from 'components/Button'
import {ErrorMessage} from 'components/ErrorMessage'
import {Text} from 'components/Text'
import {useSettings} from 'providers/AppStateProvider/hooks'
import {useCart} from 'providers/OrderIntentProvider/hooks'
import {useWaitingMutation} from 'providers/WaitingProvider'
import {RefObject, useEffect, useState} from 'react'
import {createPortal} from 'react-dom'
import {useCheckoutFormManager} from './useCheckoutFormManager'

type Props = {
  footerRef: RefObject<HTMLDivElement>
}

export const CheckoutForm = ({footerRef}: Props) => {
  const manager = useCheckoutFormManager()
  const {addWaiter, removeWaiter} = useWaitingMutation()
  const cart = useCart()
  const {currency} = useSettings()
  const [ready, setReady] = useState(false)
  const [completed, setCompleted] = useState(false)

  const onReady = () => {
    removeWaiter('stripe')
    setReady(true)
  }

  const onChange = (event: stripeJs.StripePaymentElementChangeEvent) => {
    setCompleted(event.complete)
  }

  useEffect(() => {
    addWaiter('stripe')
    return () => removeWaiter('stripe')
  }, [addWaiter, removeWaiter])

  return (
    <form onSubmit={manager.makePayment} id="stripe">
      {ready && (
        <>
          <Text.H4 className="mb-2">Card details</Text.H4>
          <Text className="mb-4">
            Please enter your card details to reserve your spot on the{' '}
            {pluralize(manager.data.cartItems.length, 'tour')}. Your card will be checked for validity, but you will not
            be charged as long as you show up with a valid pass at the tour check-in.
          </Text>
          <Text className="mb-4">
            Only upgrades will be charged. Upgrades are nonrefundable, even in the case of a no-show.
          </Text>
        </>
      )}
      <button type="submit" className="hidden" />
      <PaymentElement onChange={onChange} onReady={onReady} onLoadError={() => removeWaiter('stripe')} />
      {footerRef &&
        footerRef.current &&
        createPortal(
          <Button.Standard
            form="stripe"
            type="submit"
            text={`Pay ${formatMoney(cart.pricing.displayed.total, currency, true)}`}
            disabled={!ready || !completed}
            expand
          />,
          footerRef.current
        )}
      <ErrorMessage error={manager.error} className="mt-4" />
    </form>
  )
}
