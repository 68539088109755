import {Basic} from 'commonTypes'
import moment from 'moment'
import {useInput} from 'providers/InputProvider'
import React, {useCallback, useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import {OrderIntentInfo, StepHistoryItem} from 'types'
import {LocalStorageContextValue, LocalStorageData} from './types'

type Props = {
  children: React.ReactNode
}

class LocalStorageFailback {
  private storage: Record<string, string> = {}
  length = 0

  getItem(key: string) {
    return this.storage[key]
  }

  setItem(key: string, value: string) {
    this.storage[key] = value
  }

  removeItem(key: string) {
    delete this.storage[key]
  }

  key(n: number) {
    return ''
  }

  clear() {
    this.storage = {}
  }
}

const getLocalStorage = () => {
  try {
    return window.localStorage || new LocalStorageFailback()
  } catch (error) {
    console.error(error)
    return new LocalStorageFailback()
  }
}

const LOCAL_STORAGE = getLocalStorage()

const VERSION = '0.0.5'
const DEFAULT_DATA = {}
const TERMINAL_PATHS = ['/result', '/timeout']

export const LocalStorageContext = React.createContext<LocalStorageContextValue | null>(null)

const loadData = (key: string): LocalStorageData => {
  const localStorageDataJson = LOCAL_STORAGE.getItem(key)

  if (!localStorageDataJson) {
    return DEFAULT_DATA
  }

  try {
    const data = JSON.parse(localStorageDataJson) as LocalStorageData

    const utcExpiresAt = data.orderIntentInfo?.ventrataOrderExpiresAt
    const lastActionAt = data.lastActionAt

    const ventrataOrderExpired = utcExpiresAt && moment(utcExpiresAt).diff(moment()) <= 0
    const orderIntentExpired = lastActionAt && moment().diff(lastActionAt, 'minutes') >= 30

    if (ventrataOrderExpired || orderIntentExpired) {
      return DEFAULT_DATA
    }

    return data
  } catch (error) {
    console.error(error)
    return DEFAULT_DATA
  }
}

export const LocalStorageProvider = ({children}: Props) => {
  const {pathname} = useLocation()
  const {tourCode, configCode} = useInput()
  const key = `csw:${VERSION}:${configCode}:${tourCode}`
  const [data, setData] = useState<LocalStorageData>(loadData(key))

  useEffect(() => {
    if (!TERMINAL_PATHS.includes(pathname)) {
      LOCAL_STORAGE.setItem(key, JSON.stringify(data))
    }
  }, [pathname, data, key])

  useEffect(() => {
    if (TERMINAL_PATHS.includes(pathname)) {
      LOCAL_STORAGE.removeItem(key)
    }
  }, [key, pathname])

  const setLastActionAt = useCallback(() => {
    setData((state) => ({...state, lastActionAt: new Date().toUTCString()}))
  }, [])

  const setStepHistory = useCallback((stepHistory: StepHistoryItem[]) => {
    setData((state) => ({...state, stepHistory}))
  }, [])

  const setOrderIntentInfo = useCallback((orderIntent: Basic.OrderIntent) => {
    const orderIntentInfo: OrderIntentInfo = {
      id: orderIntent.id,
      ventrataOrderExpiresAt: orderIntent.ventrataOrder?.utcExpiresAt,
    }
    setData((state) => ({...state, orderIntentInfo}))
  }, [])

  const contextValue: LocalStorageContextValue = {
    ...data,
    setLastActionAt,
    setStepHistory,
    setOrderIntentInfo,
  }

  return <LocalStorageContext.Provider value={contextValue}>{children}</LocalStorageContext.Provider>
}
