import cx from 'classnames'
import {ReactComponent as RightIcon} from 'icons/right.svg'
import {StepItem} from 'types'
import {Step} from './Step'

type Props = {
  steps: StepItem[]
  className?: string
}

export const Stepper = ({steps, className}: Props) => {
  return (
    <div>
      <div className={cx('mx-4 sm:mx-6 flex items-center flex-wrap gap-y-2', className)}>
        {steps.map(({pathname, title, active}, index) => {
          return (
            <div className="flex items-center" key={pathname}>
              <Step pathname={pathname} title={title} active={active} />
              {index < steps.length - 1 && <RightIcon className="mx-2 sm:mx-8" />}
            </div>
          )
        })}
      </div>
    </div>
  )
}
