import {Entities} from '@exp1/ventrata-utils'
import {Basic} from 'commonTypes'
import {OrderIntent} from 'commonTypes/basic'
import {useContext} from 'react'
import {OrderIntentContext} from './provider'
import {OrderIntentValue} from './types'

export const useOrderIntentContext = (): OrderIntentValue => {
  const context = useContext(OrderIntentContext)

  if (!context) {
    throw new Error(`Order intent context is null`)
  }

  return context
}

export const useOrderIntentSetter = () => {
  return useOrderIntentContext().setOrderIntent
}

const useOrderIntentData = (property: keyof Basic.OrderIntent, throwErrorIfUndefined = true) => {
  const orderIntent = useOrderIntentContext().orderIntent

  if (!orderIntent) {
    if (throwErrorIfUndefined) {
      throw new Error('Order intent is not defined')
    }
    return orderIntent
  }

  const data = orderIntent[property]

  if (throwErrorIfUndefined && data === undefined) {
    throw new Error(`Order intent property '${property}' is not defined`)
  }

  return data
}

export const useOptionalOrderIntent = (): OrderIntent | undefined => {
  const orderIntent = useOrderIntentContext().orderIntent
  return orderIntent ? orderIntent : undefined
}

export const useOrderIntent = (): OrderIntent => {
  const orderIntent = useOrderIntentContext().orderIntent
  if (!orderIntent) {
    throw new Error('Order intent is not defined')
  }
  return orderIntent
}

export const useCart = () => {
  return useOrderIntentData('extendedCart') as Basic.ExtendedCart
}

export const useOptionalCart = () => {
  return useOrderIntentData('extendedCart', false) as Basic.ExtendedCart | undefined
}

export const useCartItem = (cartItemId: string): Basic.ExtendedCartItem => {
  const cart = useCart()
  const cartItem = cart.items.find((cartItem) => cartItem.id === cartItemId)
  if (!cartItem) {
    throw new Error(`Can't find cart item by id '${cartItemId}'`)
  }
  return cartItem
}

export const useUnitCounters = () => {
  return useOrderIntentData('unitCounters') as Entities.TypeUnitCounter[]
}

export const useOptionalUnitCounters = () => {
  return useOrderIntentData('unitCounters', false) as Entities.TypeUnitCounter[] | undefined
}

export const useContact = () => {
  return useOrderIntentData('contact') as Basic.Contact
}

export const useOptionalContact = () => {
  return useOrderIntentData('contact', false) as Basic.Contact | undefined
}

export const useOrderIntentId = () => {
  return useOrderIntentData('id') as string
}

export const useTravelers = () => {
  return useOrderIntentData('travelers') as Basic.Traveler[]
}

export const useOptionalTravelers = () => {
  return useOrderIntentData('travelers', false) as Basic.Traveler[] | undefined
}

export const useStripeSetupIntent = () => {
  return useOrderIntentData('stripeSetupIntent') as Basic.StripeSetupIntent
}

export const useOptionalVentrataOrder = () => {
  return useOrderIntentData('ventrataOrder', false) as Basic.VentrataOrder | undefined
}

export const useOptionalMailingTermsAccepted = () => {
  return useOrderIntentData('mailingTermsAccepted', false) as boolean | undefined
}
