import cx from 'classnames'
import {ReactNode} from 'react'
import {CardTourImage} from './TourImage'

type ImageProps = {
  imageSrc: string
  imageAlt: string
}

type FrameProps = {
  className?: string
  children: ReactNode
}

type Props = FrameProps | (FrameProps & ImageProps)

export const Frame = (props: Props) => {
  if ('imageSrc' in props) {
    const {imageSrc, imageAlt, children, className} = props
    return (
      <div className="flex flex-col relative">
        <CardTourImage alt={imageAlt} src={imageSrc} />
        <div className={cx('rounded-b-lg border-x border-b border-neutral-grey-dark', className)}>{children}</div>
      </div>
    )
  }

  const {children, className} = props
  return <div className={cx('rounded-lg border border-neutral-grey-dark relative', className)}>{children}</div>
}
