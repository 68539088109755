import cx from 'classnames'
import {Label} from 'components/Label'
import {Text} from 'components/Text'
import {RadioInput} from 'components/inputs/RadioInput'
import {getTailwindColor} from 'getTailwindColor'
import {Field} from 'react-final-form'

type Props = {
  label?: string
  name: string
  value: string
  className?: string
  id?: string
}

export const RadioInputField = ({label, name, className, value, id}: Props) => {
  return (
    <Field
      name={name}
      type="radio"
      value={value}
      render={({input, meta}) => {
        const {active, dirty, error} = meta
        const showError = !active && dirty && error

        return (
          <div className={cx('flex flex-col', className)}>
            <div className="flex gap-2 total-cursor-pointer w-fit">
              <RadioInput id={id} {...input} />
              {label && <Label title={label} htmlFor={id} />}
            </div>
            {showError && <Text color={getTailwindColor('brand-red')}>{error}</Text>}
          </div>
        )
      }}
    />
  )
}
