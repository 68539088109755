import {Page} from 'components/Page'
import {AppStateProvider} from 'providers/AppStateProvider'
import {InputProvider} from 'providers/InputProvider'
import {LocalStorageProvider} from 'providers/LocalStorageProvider/provider'
import {OrderIntentProvider} from 'providers/OrderIntentProvider'
import {StepHistoryProvider} from 'providers/StepHistoryProvider'
import {WaitingProvider} from 'providers/WaitingProvider'
import {QueryClient, QueryClientProvider} from 'react-query'
import {BrowserRouter as Router} from 'react-router-dom'
import {ReadyToWork} from './ReadyToWork'
import {Routing} from './Routing'
import {ShowSpinnerIfNeed} from './ShowSpinnerIfNeed'

const queryClientConfig = {
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
}

const queryClient = new QueryClient(queryClientConfig)

export const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Page>
          <InputProvider>
            <LocalStorageProvider>
              <StepHistoryProvider>
                <WaitingProvider>
                  <ShowSpinnerIfNeed>
                    <AppStateProvider>
                      <OrderIntentProvider>
                        <ReadyToWork>
                          <Routing />
                        </ReadyToWork>
                      </OrderIntentProvider>
                    </AppStateProvider>
                  </ShowSpinnerIfNeed>
                </WaitingProvider>
              </StepHistoryProvider>
            </LocalStorageProvider>
          </InputProvider>
        </Page>
      </Router>
    </QueryClientProvider>
  )
}
