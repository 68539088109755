import cx from 'classnames'
import React from 'react'
import {ReactComponent as LeftIcon} from '../../icons/left.svg'
import {ReactComponent as RightIcon} from '../../icons/right.svg'

type Props = {
  className?: string
  onClick: () => void
  direction: 'left' | 'right'
}

export const CalendarNavigation = ({direction, className, onClick}: Props) => {
  const Icon = direction === 'left' ? LeftIcon : RightIcon

  return <Icon className={cx('cursor-pointer', className)} onClick={onClick} />
}
