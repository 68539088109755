import {useElements, useStripe} from '@stripe/react-stripe-js'
import {useConfirmSetupMutation} from 'hooks/mutations/useConfirmSetupMutation'
import {useSettings} from 'providers/AppStateProvider/hooks'
import {useInput} from 'providers/InputProvider'
import {useCart, useOrderIntentId} from 'providers/OrderIntentProvider/hooks'
import {useCustomGTMEventSender} from 'providers/WidgetGTMProvider'
import {FormEvent} from 'react'

export const useCheckoutFormManager = () => {
  const settings = useSettings()
  const {siteUrl, tourCode, configCode} = useInput()
  const orderIntentId = useOrderIntentId()
  const stripe = useStripe()
  const elements = useElements()
  const confirmSetupMutation = useConfirmSetupMutation()
  const sendDataToGTM = useCustomGTMEventSender()
  const cartItems = useCart().items

  const makePayment = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    sendDataToGTM({
      Category: 'Payment',
      Action: 'Pay Button',
      event: 'gaEvent',
    })

    if (!stripe || !elements) {
      throw new Error(`Problem with Stripe elements loading`)
    }

    confirmSetupMutation.mutate(
      {
        orderIntentId,
        stripe,
        elements,
        siteUrl,
        tourCode,
        configCode,
      },
      {
        onError(error: any) {
          sendDataToGTM({
            Category: 'Payment',
            Action: 'Error',
            Label: error.message,
            event: 'gaEvent',
          })
        },
      }
    )
  }

  const error = confirmSetupMutation.error

  const data = {
    settings,
    cartItems,
  }

  return {
    data,
    error,
    makePayment,
  }
}
