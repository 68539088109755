import {useApi} from 'api'
import {AxiosError} from 'axios'
import {Params, Responses} from 'commonTypes'
import {useQuery} from 'react-query'
import {QueryOptions} from 'types'

export const useConfirmOrderQuery = ({orderIntentId}: Params.ConfirmOrder, options?: QueryOptions) => {
  const api = useApi()
  return useQuery<Responses.OrderIntent, AxiosError>(
    ['confirmOrder', orderIntentId],
    () => api.confirmOrder({orderIntentId}),
    {
      ...options,
    }
  )
}
