import {pluralize} from '@exp1/common-utils'
import {Button} from 'components/Button'
import {ErrorMessage} from 'components/ErrorMessage'
import {Left} from 'components/Left'
import {Main} from 'components/Main'
import {OrderDetails} from 'components/OrderDetails'
import {StickyFooter} from 'components/StickyFooter'
import {Text} from 'components/Text'
import {useTemplate} from 'hooks/useTemplate'
import {useSettings} from 'providers/AppStateProvider/hooks'
import {CheckoutGTMProvider} from 'providers/WidgetGTMProvider'
import {TourUpgradesCard} from './TourUpgradesCard'
import {usePageManager} from './usePageManager'

export const TourUpgrades = () => {
  const manager = usePageManager()
  const {upgradeGreeting} = useSettings()

  const upgradeGreetingTemplate = useTemplate(
    upgradeGreeting,
    'Take your experience to the next level and upgrade your tour for an unforgettable visit!'
  )

  return (
    <CheckoutGTMProvider stepName="Step 5 Upgrades">
      <>
        <Main.TwoColumn>
          <Left
            className="flex flex-col gap-4"
            title={
              manager.data.upgradesIsLoaded
                ? manager.data.hasAllowedUpgrades
                  ? 'Upgrade your tour'
                  : 'No upgrades'
                : 'Searching available upgrades...'
            }
          >
            {manager.data.hasAllowedUpgrades && (
              <>
                <Text fontSize="19px" lineHeight="32px">
                  {upgradeGreetingTemplate}
                </Text>
                {manager.data.cartItems.map((cartItem) => {
                  const allowedUpgrades = manager.data.allowedUpgrades[cartItem.id]

                  if (!allowedUpgrades || allowedUpgrades.length === 0) {
                    return null
                  }

                  return (
                    <TourUpgradesCard
                      key={cartItem.id}
                      cartItemId={cartItem.id}
                      upgrades={allowedUpgrades}
                      edited={cartItem.id === manager.data.editedCartItemId}
                    />
                  )
                })}
              </>
            )}
            {manager.data.hasNotAllowedUpgrades && (
              <Text fontSize="19px" lineHeight="32px">
                There aren't any upgrades for your selected {pluralize(manager.data.cartItems.length, 'tour')}.
                {`\u{00A0}`}
                That means all the fun is included with your pass!
              </Text>
            )}
            <ErrorMessage error={manager.error} />
          </Left>
          <OrderDetails />
        </Main.TwoColumn>
        <StickyFooter.SingleButton>
          {manager.data.hasNotAllowedUpgrades && (
            <Button.Secondary outline text={manager.data.nextButtonCaption} onClick={() => manager.save()} expand />
          )}
          {manager.data.hasAllowedUpgrades && (
            <Button.Secondary outline text={manager.data.nextButtonCaption} onClick={() => manager.save()} expand />
          )}
        </StickyFooter.SingleButton>
      </>
    </CheckoutGTMProvider>
  )
}
