import cx from 'classnames'
import React from 'react'

type Props = {
  type?: 'button' | 'submit' | 'reset'
  onClick?: () => void
  disabled?: boolean
  text: string
  className?: string
  expand?: boolean
  activeClassName: string
  disabledClassName: string
  form?: string
}

export const Button = ({
  onClick,
  disabled,
  text,
  className,
  type,
  expand,
  activeClassName,
  disabledClassName,
  form,
}: Props) => {
  return (
    <button
      type={type}
      className={cx(
        'h-12 flex items-center justify-center rounded-lg px-8 font-bold text-base',
        {
          'w-full': expand,
          'w-fit': !expand,
          'pointer-events-none': disabled,
          [activeClassName]: !disabled,
          [disabledClassName]: disabled,
        },
        className
      )}
      onClick={onClick}
      form={form}
    >
      {text}
    </button>
  )
}

type VariantProps = Omit<Props, 'activeClassName' | 'disabledClassName'> & {
  outline?: boolean
}

Button.Standard = ({outline, ...other}: VariantProps) => {
  const activeClassName = outline ? 'border border-secondary-orange text-french-blue' : 'bg-secondary-orange text-white'
  const disabledClassName = outline ? 'border border-neutral-grey text-neutral-grey' : 'bg-neutral-grey text-white'
  return <Button {...other} activeClassName={activeClassName} disabledClassName={disabledClassName} />
}

Button.Danger = ({outline, ...other}: VariantProps) => {
  const activeClassName = outline ? 'border border-french-blue text-brand-red' : 'bg-brand-red text-white'
  const disabledClassName = outline ? 'border border-neutral-grey text-neutral-grey' : 'bg-neutral-grey text-white'
  return <Button {...other} activeClassName={activeClassName} disabledClassName={disabledClassName} />
}

Button.Secondary = ({outline, ...other}: VariantProps) => {
  const activeClassName = outline ? 'border border-french-blue text-french-blue' : 'bg-french-blue text-white'
  const disabledClassName = outline ? 'border border-neutral-grey text-neutral-grey' : 'bg-neutral-grey text-white'
  return <Button {...other} activeClassName={activeClassName} disabledClassName={disabledClassName} />
}
