import {Label} from 'components/Label'
import {Text} from 'components/Text'
import {PhoneInput} from 'components/inputs/PhoneInput'
import {getTailwindColor} from 'getTailwindColor'
import React from 'react'
import {Field} from 'react-final-form'

type Props = {
  label?: string
  name: string
  type: string
  required?: boolean
}

export const PhoneInputField = ({label, type, name, required}: Props) => {
  return (
    <Field
      name={name}
      type={type}
      render={({input, meta}) => {
        const {active, dirty, error} = meta
        const showError = !active && dirty && error
        return (
          <div className="flex flex-col">
            {label && <Label title={label} required={required} />}
            <PhoneInput {...input} />
            {showError && <Text color={getTailwindColor('brand-red')}>{error}</Text>}
          </div>
        )
      }}
    />
  )
}
