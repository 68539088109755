import {useGetAvailabilitiesQuery} from 'hooks/queries/useGetAvailabilitiesQuery'
import {useGetMonthCalendarQuery} from 'hooks/queries/useGetMonthCalendarQuery'
import {useUnits} from 'providers/AppStateProvider/hooks'
import {useInput} from 'providers/InputProvider'
import {useOrderIntentSetter, useUnitCounters} from 'providers/OrderIntentProvider/hooks'
import {useHistoryStepPathOrCurrent, useIsReturnStepVisit} from 'providers/StepHistoryProvider/hooks'
import {useChangeCartEventSender} from 'providers/WidgetGTMProvider'
import {useNavigate} from 'react-router-dom'
import {Card} from '../types'
import {useCardStateManager} from './useCardStateManager'

export const useCardManager = (params: Card) => {
  const {useSaveMutation, tourCode, initialTourDate, initialCalendarMonth} = params
  const navigate = useNavigate()
  const {configCode} = useInput()
  const units = useUnits()
  const unitCounters = useUnitCounters()
  const isReturnStepVisit = useIsReturnStepVisit()
  const penultimateStepPath = useHistoryStepPathOrCurrent(-2)
  const manager = useCardStateManager({
    calendarMonth: initialCalendarMonth,
    tourDate: initialTourDate,
    availabilityId: params.booked ? params.availabilityId : null,
  })
  const saveMutation = useSaveMutation()
  const setOrderIntent = useOrderIntentSetter()
  const sendChangeCartEvent = useChangeCartEventSender()

  const save = () => {
    if (!manager.state.availabilityId) {
      throw new Error('Availability is null')
    }

    saveMutation.mutate(
      {availabilityId: manager.state.availabilityId},
      {
        async onSuccess({orderIntent, affectedCartItem}) {
          await sendChangeCartEvent(affectedCartItem, 'add')
          setOrderIntent(orderIntent)
          isReturnStepVisit ? navigate(penultimateStepPath) : navigate('/steps/addons')
        },
      }
    )
  }

  const getCalendarQuery = useGetMonthCalendarQuery(
    {
      configCode,
      tourCode,
      unitCounters,
      calendarMonth: manager.state.calendarMonth,
    },
    {
      keepPreviousData: true,
    }
  )

  const getAvailabilitiesQuery = useGetAvailabilitiesQuery(
    {
      configCode,
      tourCode,
      unitCounters,
      tourDate: manager.state.tourDate,
    },
    {
      keepPreviousData: true,
    }
  )

  const error = getCalendarQuery.error || getAvailabilitiesQuery.error || saveMutation.error

  const availabilities = getAvailabilitiesQuery.data?.availabilities || null
  const days = getCalendarQuery.data?.calendar

  const data = {
    availabilities,
    days,
    units,
  }

  return {
    state: manager.state,
    error,
    data,
    save,
    isCompleted: manager.isCompleted,
    changeTourDate: manager.changeTourDate,
    changeCalendarMonth: manager.changeCalendarMonth,
    changeAvailabilityId: manager.changeAvailabilityId,
  }
}
