import {Text} from 'components/Text'
import {ReactComponent as ElevatorIcon} from 'icons/elevator.svg'
import {ReactComponent as SpanishIcon} from 'icons/spanish.svg'
import {ReactComponent as StairsIcon} from 'icons/stairs.svg'
import {capitalize} from 'lodash'
import {FC, SVGProps} from 'react'

type Props = {
  tag: string
}

const tags: Record<string, FC<SVGProps<SVGSVGElement>>> = {
  stairs: StairsIcon,
  elevator: ElevatorIcon,
  spanish: SpanishIcon,
}

export const TourTag = ({tag}: Props) => {
  const Icon = tags[tag]

  return (
    <div className="flex gap-2 items-center">
      {Icon && <Icon height={16} width={16} />}
      <Text>{capitalize(tag)}</Text>
    </div>
  )
}
