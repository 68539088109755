import {useLastActionAtSetter, useStepHistory, useStepHistorySetter} from 'providers/LocalStorageProvider/hooks'
import React, {useCallback, useEffect, useState} from 'react'
import {Location} from 'react-router'
import {StepHistoryItem} from 'types'
import {StepHistoryContextValue} from './types'

type Props = {
  children: React.ReactNode
}

export const StepHistoryContext = React.createContext<StepHistoryContextValue | null>(null)

export const StepHistoryProvider = ({children}: Props) => {
  const savedStepHistory = useStepHistory()
  const [state, setState] = useState<StepHistoryItem[]>(savedStepHistory)
  const setStepHistory = useStepHistorySetter()
  const setLastActionAt = useLastActionAtSetter()

  const push = useCallback((location: Location, index: number) => {
    const newItem: StepHistoryItem = {
      pathname: location.pathname,
      search: location.search,
      index,
    }
    setState((state) => {
      if (state.length > 0) {
        const lastItem = state[state.length - 1]
        if (
          newItem.pathname === lastItem.pathname &&
          newItem.search === lastItem.search &&
          newItem.index === lastItem.index
        ) {
          return state
        }
      }
      return [...state, newItem]
    })
  }, [])

  useEffect(() => {
    setStepHistory(state)
    setLastActionAt()
  }, [setLastActionAt, setStepHistory, state])

  const appContextValue = {
    stepHistory: state,
    push,
  }

  return <StepHistoryContext.Provider value={appContextValue}>{children}</StepHistoryContext.Provider>
}
