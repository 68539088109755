import cx from 'classnames'
import {Label} from 'components/Label'
import {Text} from 'components/Text'
import {Input} from 'components/inputs/Input'
import {getTailwindColor} from 'getTailwindColor'
import React from 'react'
import {Field} from 'react-final-form'

type Props = {
  label?: string
  name: string
  type: string
  required?: boolean
  placeholder?: string
  className?: string
  validate?: (value: any) => any
  format?: (value: any) => any
  parse?: (value: any) => any
  min?: number
  max?: number
  autoFocus?: boolean
}

export const StandardInputField = ({
  label,
  type,
  name,
  required,
  placeholder,
  className,
  validate,
  format,
  parse,
  min,
  max,
  autoFocus,
}: Props) => {
  return (
    <Field
      validate={validate}
      format={format}
      parse={parse}
      name={name}
      type={type}
      render={({input, meta}) => {
        const {active, dirty, error} = meta
        const showError = !active && dirty && error
        return (
          <div className={cx('flex flex-col', className)}>
            {label && <Label title={label} required={required} />}
            <Input {...input} placeholder={placeholder} min={min} max={max} autoFocus={autoFocus} />
            {showError && <Text color={getTailwindColor('brand-red')}>{error}</Text>}
          </div>
        )
      }}
    />
  )
}
