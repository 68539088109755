import cx from 'classnames'
import {ErrorMessage} from 'components/ErrorMessage'
import {Link} from 'components/Link'
import {Text} from 'components/Text'
import {get} from 'lodash'
import React from 'react'

type Props = {
  error: any
  className?: string
}

export const CustomErrorMessage = ({error, className}: Props) => {
  if (get(error, 'response.data.error.code') === 'invalid_date') {
    return (
      <div className={cx('flex flex-col', className)}>
        <div className="text-red-700" role="alert">
          Sorry, we’re soldout for this date.
        </div>
        <div>
          <Text.Span>You can change the </Text.Span>
          <Link type="internal" to="/steps/booking_slot">
            timeslot for your experience
          </Link>
          <Text.Span>.</Text.Span>
        </div>
      </div>
    )
  }

  return <ErrorMessage error={error} className={className} />
}
