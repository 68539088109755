import {Text} from 'components/Text'
import {ReactComponent as EditIcon} from 'icons/edit.svg'
import {ReactComponent as PeopleIcon} from 'icons/people.svg'
import {useOptionalUnitCounters} from 'providers/OrderIntentProvider/hooks'
import {Fragment} from 'react'
import {useNavigate} from 'react-router-dom'
import {CartUnit} from './CartUnit'

export const CartUnits = () => {
  const navigate = useNavigate()
  const unitCounters = useOptionalUnitCounters()

  if (!unitCounters) {
    return null
  }

  const filteredUnitCounters = unitCounters.filter((unitCounter) => unitCounter.quantity !== 0)

  return (
    <div className="flex gap-3 items-center cursor-pointer" onClick={() => navigate('/steps/unit_items')}>
      <PeopleIcon />
      <div className="flex">
        {filteredUnitCounters.map((unitCounter, i) => (
          <Fragment key={unitCounter.type}>
            <CartUnit unitCounter={unitCounter} />
            {i < filteredUnitCounters.length - 1 && <Text className="whitespace-pre"> • </Text>}
          </Fragment>
        ))}
      </div>
      <EditIcon />
    </div>
  )
}
