import cx from 'classnames'
import {Link} from 'components/Link'
import {Text} from 'components/Text'
import {get} from 'lodash'
import {useOptionalSettings} from 'providers/AppStateProvider/hooks'

type Props = {
  error: any
  className?: string
}

const getErrorMessage = (error: any) => {
  if (typeof error === 'string') {
    return error
  }

  return get(error, 'response.data.error.message') || get(error, 'message') || 'Unknown error'
}

export const ErrorMessage = ({error, className}: Props) => {
  const settings = useOptionalSettings()
  const contactUs = get(settings, 'links.contactUs') || 'https://exp1.com/contact'

  if (!error) {
    return null
  }

  return (
    <div className={cx('flex flex-col', className)}>
      <div className="text-red-700" role="alert">
        {getErrorMessage(error)}
      </div>
      <div>
        <Text.Span>If you have any questions, please </Text.Span>
        <Link type="external" href={contactUs}>
          contact us
        </Link>
        <Text.Span>.</Text.Span>
      </div>
    </div>
  )
}
