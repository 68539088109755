import codes from 'country-calling-code'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'

interface ICountryHash {
  [countryCode: string]: {
    phoneCode: string
    flagIcon: string
    countryName: string
  }
}

export const COUNTRY_HASH = codes.reduce((result: ICountryHash, {isoCode2, countryCodes, country: countryName}) => {
  const phoneCode = countryCodes[0]
  const flagIcon = getUnicodeFlagIcon(isoCode2)

  result[isoCode2] = {
    phoneCode,
    flagIcon,
    countryName,
  }

  return result
}, {})

export const stringifyPhone = (phoneCode: string, countryCode: string, phoneNumber: string) => {
  return `${countryCode}|${phoneCode}|${phoneNumber}`
}

export const parsePhone = (value: string) => {
  const [countryCode, phoneCode, phoneNumber] = value.split('|')
  return {
    phoneCode,
    countryCode,
    phoneNumber,
  }
}

export const getEmptyPhone = (countryCode: string) => {
  const data = COUNTRY_HASH[countryCode]

  return data ? `${countryCode}|${data.phoneCode}|` : 'US|1|'
}
