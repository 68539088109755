import {CalendarDate, CalendarMonth} from 'components/Calendars/types'
import {Moment} from 'moment'

export const momentToCalendarDate = (date: Moment): CalendarDate => {
  return {
    year: date.year(),
    month: date.month(),
    date: date.date(),
  }
}

export const momentToCalendarMonth = (date: Moment): CalendarMonth => {
  return {
    year: date.year(),
    month: date.month(),
  }
}
