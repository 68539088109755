import {Basic} from 'commonTypes'
import {useSetTravelersMutation} from 'hooks/mutations/useSetTravelersMutation'
import {
  useOptionalTravelers,
  useOrderIntentId,
  useOrderIntentSetter,
  useUnitCounters,
} from 'providers/OrderIntentProvider/hooks'
import {useHistoryStepPathOrCurrent, useIsReturnStepVisit} from 'providers/StepHistoryProvider/hooks'
import {useNavigate} from 'react-router-dom'
import {countGuests} from 'utils/countGuests'

const getInitialValues = (guestQuantity: number, travelers: Basic.Traveler[] | undefined = []) => {
  const initialTravelers = []

  for (let i = 0; i < guestQuantity; i++) {
    if (travelers.length > i) {
      initialTravelers.push(travelers[i])
    } else {
      initialTravelers.push({})
    }
  }

  return {travelers: initialTravelers}
}

type TravelerError = {
  age?: string
  pass?: string
}

export const usePageManager = () => {
  const navigate = useNavigate()
  const orderIntentId = useOrderIntentId()
  const travelers = useOptionalTravelers()
  const unitCounters = useUnitCounters()
  const setTravelersMutation = useSetTravelersMutation()
  const setOrderIntent = useOrderIntentSetter()
  const guestQuantity = countGuests(unitCounters)
  const isReturnStepVisit = useIsReturnStepVisit()
  const penultimateStepPath = useHistoryStepPathOrCurrent(-2)

  const error = setTravelersMutation.error
  const isEdit = isReturnStepVisit
  const initialValues = getInitialValues(guestQuantity, travelers)

  const save = ({travelers}: {travelers: Basic.Traveler[]}) => {
    setTravelersMutation.mutate(
      {
        orderIntentId,
        travelers,
      },
      {
        onSuccess({orderIntent}) {
          setOrderIntent(orderIntent)
          isEdit ? navigate(penultimateStepPath) : navigate('/steps/payment')
        },
      }
    )
  }

  const validate = ({travelers}: {travelers: Partial<Basic.Traveler>[]}) => {
    const errors: {travelers: TravelerError[]} = {travelers: []}

    for (const traveler of travelers) {
      const error: TravelerError = {}
      errors.travelers.push(error)

      if (typeof traveler.age !== 'number' || !(traveler.age >= 0 && traveler.age <= 100)) {
        error.age = 'Age must be between 0 and 100'
      }

      if (traveler.pass && !traveler.pass.trim().match(/^\d{12}$/)) {
        error.pass = 'Pass must be 12 digits'
      }
    }

    const foundErrors = errors.travelers.find((error) => {
      return error.age !== undefined || error.pass !== undefined
    })

    return foundErrors ? errors : undefined
  }

  const data = {
    guestQuantity,
    initialValues,
  }

  return {
    isEdit,
    error,
    data,
    save,
    validate,
  }
}
