import cx from 'classnames'
import {ReactNode} from 'react'

type Props = {
  children?: ReactNode
  className?: string
}

export const Right = ({children, className}: Props) => {
  return <div className={cx('w-full md:w-right-side', className)}>{children}</div>
}
