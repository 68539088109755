import {Basic} from 'commonTypes'
import {SmallTourCard} from 'components/TourCards/SmallTourCard'
import {useCartItem} from 'providers/OrderIntentProvider/hooks'
import {getDisplayedPrice} from 'utils/getDisplayedPrice'
import {UpgradeTourCard} from './UpgradeTourCard'

type Props = {
  cartItemId: string
  upgrades: Basic.TourUpgrade[]
  edited: boolean
}

export const TourUpgradesCard = ({cartItemId, upgrades, edited}: Props) => {
  const cartItem = useCartItem(cartItemId)
  return (
    <div className="flex flex-col rounded-lg divide-y divide-neutral-grey border border-neutral-grey">
      <SmallTourCard tourCode={cartItem.original.tour.code} className="p-4" />
      {upgrades.map(({tour, priceDifferences, images, description}) => (
        <UpgradeTourCard
          key={tour.code}
          tourCode={tour.code}
          priceDifferences={priceDifferences}
          amountDifference={getDisplayedPrice(cartItem.unitCounters, priceDifferences)}
          description={description}
          cartItemId={cartItemId}
          images={images}
          upgraded={tour.code === cartItem.upgraded?.tour.code}
          className="p-4"
          edited={edited}
        />
      ))}
    </div>
  )
}
