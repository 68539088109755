import {ErrorMessage} from 'components/ErrorMessage'
import {Left} from 'components/Left'
import {Link} from 'components/Link'
import {Main} from 'components/Main'
import {Right} from 'components/Right'
import {Text} from 'components/Text'
import {sortBy} from 'lodash'
import {useSettings} from 'providers/AppStateProvider/hooks'
import {useCart, useContact} from 'providers/OrderIntentProvider/hooks'
import {WidgetGTMProvider} from 'providers/WidgetGTMProvider'
import {CartItem} from './CartItem'
import {usePageManager} from './usePageManager'

export const ThankYou = () => {
  const contact = useContact()
  const cart = useCart()
  const settings = useSettings()
  const {error, ready} = usePageManager()

  const sortedCartItems = sortBy(cart.items, 'availabilityId')

  if (!ready) {
    return (
      <Main.Simple title="Order confirmation...">
        <ErrorMessage error={error} />
      </Main.Simple>
    )
  }

  return (
    <Main.TwoColumn>
      <Left className="w-full flex flex-col gap-4" title={`Thank you, ${contact.firstName}`}>
        <Text>You're all set for:</Text>
        <div className="flex flex-col gap-10">
          {sortedCartItems.map((cartItem) => (
            <CartItem key={cartItem.id} cartItem={cartItem} />
          ))}
        </div>
      </Left>
      <Right>
        <Text>If you didn't receive an order confirmation email, please check your spam folder.</Text>
        <Text.Span>If you don't see it there, please </Text.Span>
        <Link type="external" href={settings.templates['contactUs']}>
          contact us
        </Link>
        <Text.Span>.</Text.Span>
      </Right>
    </Main.TwoColumn>
  )
}

export const Result = () => {
  return (
    <WidgetGTMProvider>
      <ThankYou />
    </WidgetGTMProvider>
  )
}
