import {Left} from 'components/Left'
import {Main} from 'components/Main'
import {OrderDetails} from 'components/OrderDetails'
import {CheckoutGTMProvider} from 'providers/WidgetGTMProvider'
import {BookingCard} from './BookingCard'
import {useCards} from './useCards'

export const BookingSlot = () => {
  const cards = useCards()

  return (
    <CheckoutGTMProvider stepName="Step 3 Timeslot">
      <Main.TwoColumn>
        <Left className="flex flex-col gap-4" title="Select time slot">
          {cards.map((card) => (
            <BookingCard {...card} key={card.tourCode} hideExpandButton={cards.length === 1} />
          ))}
        </Left>
        <OrderDetails />
      </Main.TwoColumn>
    </CheckoutGTMProvider>
  )
}
