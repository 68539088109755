import {formatMoney} from '@exp1/common-utils'
import {Basic} from 'commonTypes'
import {Text} from 'components/Text'
import {TourDetails} from 'components/TourDetails'
import {sumBy} from 'lodash'
import {useTour} from 'providers/AppStateProvider/hooks'
import {useOrderIntent} from 'providers/OrderIntentProvider/hooks'
import {countGuests} from 'utils/countGuests'
import {formatAvailabilityId} from 'utils/formatAvailabilityId'
import {getActualExtendedBooking} from 'utils/getActualTourCode'

type Props = {
  cartItem: Basic.ExtendedCartItem
}

const Line = ({label, value}: {label: string; value: string | number}) => {
  return (
    <div className="flex h-10 items-center justify-between">
      <Text>{label}</Text>
      <Text>{value}</Text>
    </div>
  )
}

export const CartItem = ({cartItem}: Props) => {
  const {currency} = useOrderIntent()
  const actualBooking = getActualExtendedBooking(cartItem)
  const tour = useTour(actualBooking.tour.code)

  const amount = actualBooking.pricing.displayed + sumBy(cartItem.mandatoryPayments, 'pricing')

  return (
    <div className="flex flex-col gap-3">
      <TourDetails tourCode={tour.code} largeMainName />
      <div className="flex flex-col divide-y">
        <Line label="Reference" value={actualBooking.ventrataBooking.supplierReference} />
        <Line label="Paid" value={formatMoney(amount, currency, true)} />
        <Line label="Persons" value={countGuests(cartItem.unitCounters)} />
        <Line label="Duration" value={tour.option.duration} />
        <Line label="When" value={formatAvailabilityId(cartItem.availabilityId).toUpperCase()} />
      </div>
    </div>
  )
}
