import {useSettings} from 'providers/AppStateProvider/hooks'

export const useTemplate = (keyOrValue: string | undefined, defaultValue: string) => {
  const {templates} = useSettings()

  if (keyOrValue === undefined) {
    return defaultValue
  }

  if (keyOrValue.startsWith('$')) {
    const key = keyOrValue
    const template = templates[key.slice(1)]
    return template === undefined ? defaultValue : template
  }

  const value = keyOrValue
  return value
}
