import {Button} from 'components/Button'
import {ErrorMessage} from 'components/ErrorMessage'
import {Left} from 'components/Left'
import {Main} from 'components/Main'
import {OrderDetails} from 'components/OrderDetails'
import {StickyFooter} from 'components/StickyFooter'
import {StandardTourCard} from 'components/TourCards/StandardTourCard'
import {UnitCounters} from 'components/UnitCounters'
import {CheckoutGTMProvider} from 'providers/WidgetGTMProvider'
import {usePageManager} from './usePageManager'

export const UnitItems = () => {
  const manager = usePageManager()

  return (
    <CheckoutGTMProvider stepName="Step 1 Travelers">
      <>
        <Main.TwoColumn>
          <Left className="flex flex-col gap-4" title="Select number of travelers">
            {manager.displayTourCard && <StandardTourCard tourCode={manager.data.tourCode} />}
            <UnitCounters
              units={manager.data.units}
              unitCounters={manager.unitCounters}
              onPlus={manager.plus}
              onMinus={manager.minus}
              pricing={manager.data.totalPricing}
            />
            <ErrorMessage error={manager.error} />
          </Left>
          <OrderDetails />
        </Main.TwoColumn>
        <StickyFooter.SingleButton>
          <Button.Standard
            text={manager.isEdit ? 'Save' : 'Next'}
            onClick={manager.setUnitCounters}
            disabled={!manager.isCompleted()}
            expand
          />
        </StickyFooter.SingleButton>
      </>
    </CheckoutGTMProvider>
  )
}
