import {ErrorMessage} from 'components/ErrorMessage'
import {useGetSettingsQuery} from 'hooks/queries/useGetSettingsQuery'
import {useGetUnitsQuery} from 'hooks/queries/useGetUnitsQuery'
import {useInput} from 'providers/InputProvider'
import React, {useState} from 'react'
import {AppState} from './types'

type Props = {
  children: React.ReactNode
}

export const AppContext = React.createContext<AppState>({})

export const AppStateProvider = ({children}: Props) => {
  const {tourCode, configCode} = useInput()
  const [state, setState] = useState<AppState>({})

  const getUnitsQuery = useGetUnitsQuery(
    {configCode, tourCode},
    {
      onSuccess({units}) {
        setState((state) => {
          return {...state, units}
        })
      },
    }
  )

  const getSettingsQuery = useGetSettingsQuery(
    {configCode, tourCode},
    {
      onSuccess({settings}) {
        setState((state) => {
          return {...state, settings}
        })
      },
    }
  )

  const error = getSettingsQuery.error || getUnitsQuery.error

  if (error) {
    return <ErrorMessage error={error} />
  }

  return <AppContext.Provider value={state}>{children}</AppContext.Provider>
}
