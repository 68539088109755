import {formatMoney} from '@exp1/common-utils'
import {IconButton} from 'components/IconButton'
import {SmallTourImage} from 'components/SmallTourImage'
import {Text} from 'components/Text'
import {TourDetails} from 'components/TourDetails'
import {useRemoveCartItemMutation} from 'hooks/mutations/useRemoveCartItemMutation'
import {ReactComponent as EditIcon} from 'icons/edit.svg'
import {ReactComponent as MinusIcon} from 'icons/remove.svg'
import {useSettings, useTour} from 'providers/AppStateProvider/hooks'
import {useCart, useOrderIntentId, useOrderIntentSetter} from 'providers/OrderIntentProvider/hooks'
import {useChangeCartEventSender} from 'providers/WidgetGTMProvider'
import {useNavigate} from 'react-router-dom'
import {formatAvailabilityId} from 'utils/formatAvailabilityId'
import {RemoveConfirmation} from './RemoveConfirmation'

type Props = {
  tourCode: string
  availabilityId: string
  amount: number
  cartItemId: string
}

export const OriginalTour = ({tourCode, availabilityId, amount, cartItemId}: Props) => {
  const {currency} = useSettings()
  const cart = useCart()
  const navigate = useNavigate()
  const orderIntentId = useOrderIntentId()
  const setOrderIntent = useOrderIntentSetter()
  const removeBookingMutation = useRemoveCartItemMutation()
  const modal = RemoveConfirmation.useModal()
  const tour = useTour(tourCode)
  const sendChangeCartEvent = useChangeCartEventSender()

  const removeBooking = () => {
    modal.close()
    removeBookingMutation.mutate(
      {
        orderIntentId,
        cartItemId,
      },
      {
        async onSuccess({orderIntent, affectedCartItem}) {
          await sendChangeCartEvent(affectedCartItem, 'remove')
          setOrderIntent(orderIntent)
        },
      }
    )
  }

  return (
    <div className="flex flex-col">
      <div className="flex gap-4">
        <SmallTourImage tourImage={tour.images.logo} className="shrink-0" />
        <div className="w-full">
          <div className="flex tracking-[.04em]">
            <Text fontSize="13px" lineHeight="16px">
              {formatAvailabilityId(availabilityId, true).toUpperCase()}
            </Text>
            <Text fontSize="13px" lineHeight="16px" className="whitespace-pre">
              {' '}
              •{' '}
            </Text>
            <Text fontSize="13px" lineHeight="16px" className="justify-self-end">
              {formatMoney(amount, currency, true)}
            </Text>
          </div>
          <Text fontSize="13px" lineHeight="16px" className="whitespace-pre">
            {tour.option.duration}
          </Text>
          <TourDetails tourCode={tourCode} onlyOption />
          <div className="flex gap-6 h-8">
            <IconButton
              icon={EditIcon}
              onClick={() => navigate(`/steps/booking_slot?cart_item_id=${cartItemId}`)}
              title="Edit"
            />
            <IconButton
              icon={MinusIcon}
              onClick={() => modal.open(tour)}
              disabled={cart.items.length === 1}
              title="Delete"
              className="text-brand-red"
            />
          </div>
        </div>
      </div>
      <RemoveConfirmation state={modal.state} onClose={modal.close} onRemove={removeBooking} />
    </div>
  )
}
