import {Entities, UNIT_TYPES_UNION} from '@exp1/ventrata-utils'
import {cloneDeep} from 'lodash'

export const plus = (unitCounters: Entities.TypeUnitCounter[], type: UNIT_TYPES_UNION) => {
  const clonedUnitCounters = cloneDeep(unitCounters)
  const counter = clonedUnitCounters.find((item) => item.type === type)

  if (!counter) {
    throw new Error(`Can't find counter with type '${type}'`)
  }

  counter.quantity++

  return clonedUnitCounters
}
