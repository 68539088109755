import {useOptionalSettings, useOptionalTours, useOptionalUnits} from 'providers/AppStateProvider/hooks'
import {useOptionalOrderIntent} from 'providers/OrderIntentProvider/hooks'
import {ReactNode} from 'react'

type Props = {
  children: ReactNode
}

export const ReadyToWork = ({children}: Props) => {
  const tours = useOptionalTours()
  const settings = useOptionalSettings()
  const units = useOptionalUnits()
  const orderIntent = useOptionalOrderIntent()

  const ready = tours && settings && units && orderIntent

  if (!ready) {
    return null
  }

  return <>{children}</>
}
