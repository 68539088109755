import {Acceptance} from 'components/Acceptance'
import {Button} from 'components/Button'
import {Left} from 'components/Left'
import {Link} from 'components/Link'
import {Main} from 'components/Main'
import {OrderDetails} from 'components/OrderDetails'
import {StickyFooter} from 'components/StickyFooter'
import {Text} from 'components/Text'
import {PhoneInputField} from 'components/fields/PhoneInputField'
import {StandardInputField} from 'components/fields/StandardInputField'
import {useSettings} from 'providers/AppStateProvider/hooks'
import {CheckoutGTMProvider} from 'providers/WidgetGTMProvider'
import {useRef} from 'react'
import {createPortal} from 'react-dom'
import {Form} from 'react-final-form'
import {usePageManager} from './usePageManager'

export const Contact = () => {
  const manager = usePageManager()
  const settings = useSettings()
  const footerRef = useRef<HTMLDivElement>(null)

  return (
    <CheckoutGTMProvider stepName="Step 6 Contacts">
      <>
        <Main.TwoColumn>
          <Left title="Add contact information" className="flex flex-col gap-4">
            <Form
              keepDirtyOnReinitialize
              initialValues={manager.data.initialValues}
              validate={manager.validate}
              onSubmit={manager.save}
              render={({handleSubmit, invalid}) => {
                return (
                  <form onSubmit={handleSubmit} id="contacts">
                    <div className="flex flex-col gap-4">
                      <StandardInputField label="First name" name="firstName" type="text" autoFocus={true} />
                      <StandardInputField label="Last name" name="lastName" type="text" />
                      <StandardInputField
                        label="Email"
                        name="emailAddress"
                        type="email"
                        placeholder="email@example.com"
                      />
                      <StandardInputField
                        label="Confirm email"
                        name="confirmedEmailAddress"
                        type="email"
                        placeholder="email@example.com"
                      />
                      <PhoneInputField label="Phone" name="phoneNumber" type="text" />
                      <Acceptance
                        active={!manager.isEdit}
                        accepted={manager.data.cancellationTermsAccepted}
                        onChange={manager.toggleCancellationTermsAcceptance}
                        inactiveAcceptedState={
                          <div>
                            <Text.Span>I agreed that I have </Text.Span>
                            <Link type="external" href={settings.templates['termsOfService']}>
                              24 hours before the start
                            </Link>
                            <Text.Span> of the tour to cancel or reschedule without any obligations.</Text.Span>
                          </div>
                        }
                        activeState={
                          <div>
                            <Text.Span>I agree that I have </Text.Span>
                            <Link type="external" href={settings.templates['termsOfService']}>
                              24 hours before the start
                            </Link>
                            <Text.Span> of the tour to cancel or reschedule without any obligations.</Text.Span>
                          </div>
                        }
                      />
                      <Acceptance
                        active={!manager.isEdit}
                        accepted={manager.data.chargingTermsAccepted}
                        onChange={manager.toggleChargingTermsAcceptance}
                        inactiveAcceptedState={
                          <div>
                            <Text.Span>I agreed that </Text.Span>
                            <Link type="external" href={settings.templates['termsOfService']}>
                              my credit card will be charged
                            </Link>
                            <Text.Span> the full price of the tour if I do not show up with a valid pass.</Text.Span>
                          </div>
                        }
                        activeState={
                          <div>
                            <Text.Span>I agree that </Text.Span>
                            <Link type="external" href={settings.templates['termsOfService']}>
                              my credit card will be charged
                            </Link>
                            <Text.Span> the full price of the tour if I do not show up with a valid pass.</Text.Span>
                          </div>
                        }
                      />
                      <Acceptance
                        active={!manager.isEdit}
                        accepted={manager.data.mailingTermsAccepted}
                        onChange={manager.toggleMailingTermsAcceptance}
                        inactiveAcceptedState={<Text.Span>I agreed to receive news and discounts via email.</Text.Span>}
                        inactiveDeclinedState={
                          <Text.Span>I don't want to receive news and discounts via email.</Text.Span>
                        }
                        activeState={<Text.Span>Yes, please send me news and discounts via email.</Text.Span>}
                      />
                    </div>
                    {footerRef &&
                      footerRef.current &&
                      createPortal(
                        <Button.Standard
                          form="contacts"
                          text={manager.isEdit ? 'Save' : 'Next'}
                          type="submit"
                          disabled={
                            !manager.data.chargingTermsAccepted || !manager.data.cancellationTermsAccepted || invalid
                          }
                          expand
                        />,
                        footerRef.current
                      )}
                  </form>
                )
              }}
            />
          </Left>
          <OrderDetails />
        </Main.TwoColumn>
        <StickyFooter.SingleButton ref={footerRef} />
      </>
    </CheckoutGTMProvider>
  )
}
