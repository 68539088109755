import {Entities} from '@exp1/ventrata-utils'
import {Text} from 'components/Text'
import {range} from 'lodash'
import moment from 'moment'
import {CalendarDay} from './CalendarDay'
import {CalendarNavigation} from './CalendarNavigation'
import {CalendarDate, CalendarMonth, DOW} from './types'

type Props = {
  tourDate: CalendarDate | null
  calendarMonth: CalendarMonth
  onChangeTourDate: (date: CalendarDate | null) => void
  onChangeCalendarMonth: (month: CalendarMonth) => void
  days: Entities.CalendarDay[] | null
}

const incMonth = (current: CalendarMonth): CalendarMonth => {
  return current.month < 11 ? {...current, month: current.month + 1} : {...current, month: 0, year: current.year + 1}
}

const decMonth = (current: CalendarMonth): CalendarMonth => {
  return current.month > 0 ? {...current, month: current.month - 1} : {...current, month: 11, year: current.year - 1}
}

export const MonthCalendar = ({days, onChangeTourDate, onChangeCalendarMonth, tourDate, calendarMonth}: Props) => {
  if (!days) {
    return null
  }

  const changeCalendarDate = (date: CalendarDate) => {
    onChangeTourDate(date)
  }

  const changeCalendarMonth = (month: CalendarMonth) => {
    onChangeCalendarMonth(month)
  }

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center justify-between mt-2">
        <Text.Bold>
          {moment.months(calendarMonth.month)} {calendarMonth.year}
        </Text.Bold>
        <div className="flex items-center gap-3">
          <CalendarNavigation onClick={() => changeCalendarMonth(decMonth(calendarMonth))} direction="left" />
          <CalendarNavigation onClick={() => changeCalendarMonth(incMonth(calendarMonth))} direction="right" />
        </div>
      </div>
      <div className="grid grid-cols-[repeat(7,min-content)] justify-between gap-y-6 justify-items-center">
        {DOW.map((name) => (
          <Text.Bold key={name}>{name}</Text.Bold>
        ))}
        {range(1, moment(days[0].localDate).day() || 7).map((i) => (
          <span key={i} />
        ))}
        {days.map((day) => (
          <CalendarDay
            key={day.localDate}
            day={day}
            calendarDate={tourDate}
            onChangeCalendarDate={changeCalendarDate}
          />
        ))}
      </div>
    </div>
  )
}
