import {formatMoney} from '@exp1/common-utils'
import {Text} from 'components/Text'
import {useSettings} from 'providers/AppStateProvider/hooks'
import {useOptionalCart} from 'providers/OrderIntentProvider/hooks'
import {OrderComponent} from './OrderComponent'

export const Pricing = () => {
  const cart = useOptionalCart()
  const {currency} = useSettings()
  const upgradesAmount = cart ? cart.pricing.displayed.upgrades : 0
  const mandatoryPaymentsAmount = cart ? cart.pricing.displayed.mandatoryPayments : 0
  const totalAmount = cart ? cart.pricing.displayed.total : 0

  return (
    <OrderComponent className="grid grid-cols-2 gap-y-2">
      <Text>Tour subtotal</Text>
      <Text className="justify-self-end">{formatMoney(0, currency, true)}</Text>

      <Text>Upgrades</Text>
      <Text className="justify-self-end">{formatMoney(upgradesAmount, currency, true)}</Text>

      {mandatoryPaymentsAmount > 0 && (
        <>
          <Text>Mandatory payments</Text>
          <Text className="justify-self-end">{formatMoney(mandatoryPaymentsAmount, currency, true)}</Text>
        </>
      )}

      <Text.Bold>Total to pay</Text.Bold>
      <Text.Bold className="justify-self-end">{formatMoney(totalAmount, currency, true)}</Text.Bold>
    </OrderComponent>
  )
}
