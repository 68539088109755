import {IconButton} from 'components/IconButton'
import {Text} from 'components/Text'
import {ReactComponent as MinusIcon} from 'icons/minus.svg'
import {ReactComponent as PlusIcon} from 'icons/plus.svg'
import React from 'react'

type Props = {
  initialQuantity: number
  currentQuantity: number
  onPlus: () => void
  onMinus: () => void
}

export const Counter = ({currentQuantity, onPlus, onMinus}: Props) => {
  return (
    <div className="flex items-center">
      <IconButton icon={MinusIcon} onClick={onMinus} />
      <Text fontWeight={500} fontSize="22px" lineHeight="24px" className="w-11 text-center">
        {currentQuantity}
      </Text>
      <IconButton icon={PlusIcon} onClick={onPlus} />
    </div>
  )
}
