import {Spinner} from 'components/Spinner'
import {useIsLoading} from 'hooks/useIsLoading'
import {useIsWaiting} from 'providers/WaitingProvider'
import {ReactNode} from 'react'

type Props = {
  children: ReactNode
}

export const ShowSpinnerIfNeed = ({children}: Props) => {
  const isLoading = useIsLoading()
  const isWaiting = useIsWaiting()
  const showSpinner = isLoading || isWaiting

  return (
    <>
      {children}
      {showSpinner && <Spinner />}
    </>
  )
}
