import {maxBy, nth} from 'lodash'
import {useContext} from 'react'
import {useLocation} from 'react-router-dom'
import {StepHistoryItem} from 'types'
import {StepHistoryContext} from './provider'
import {StepHistoryContextValue} from './types'

export const useStepHistoryContext = (): StepHistoryContextValue => {
  const context = useContext(StepHistoryContext)

  if (!context) {
    throw new Error(`Step history context is null`)
  }

  return context
}

export const useStepHistoryPusher = () => {
  return useStepHistoryContext().push
}

export const useStepHistory = (): StepHistoryItem[] => {
  return useStepHistoryContext().stepHistory
}

export const useFarthestStepIndex = (): number => {
  const stepHistory = useStepHistory()
  const farthestStep = maxBy(stepHistory, 'index')
  return farthestStep ? farthestStep.index : 0
}

export const useIsReturnStepVisit = () => {
  const location = useLocation()
  const farthestStepIndex = useFarthestStepIndex()
  const stepHistory = useStepHistory()
  const step = stepHistory.find((step) => step.pathname === location.pathname)
  return step ? step.index < farthestStepIndex : false
}

export const useHistoryStepPath = (index: number) => {
  const stepHistory = useStepHistory()
  const step = nth(stepHistory, index)
  return step ? `${step.pathname}${step.search}` : undefined
}

export const useHistoryStepPathOrCurrent = (index: number) => {
  const location = useLocation()
  const path = useHistoryStepPath(index)
  return path || `${location.pathname}${location.search}`
}
