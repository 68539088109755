import cx from 'classnames'
import React from 'react'

export const Input = ({
  className,
  ...other
}: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>) => {
  return (
    <input
      className={cx(
        'bg-input-background border border-input-border text-french-blue text-base leading-6 text-sm rounded outline-none block w-full p-2.5 placeholder:text-input-placeholder placeholder:text-base placeholder:leading-6',
        className
      )}
      {...other}
    />
  )
}
