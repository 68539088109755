import {StripeElementsOptions, loadStripe} from '@stripe/stripe-js'
import {useDietaryRestrictionsNecessity} from 'hooks/useDietaryRestrictionsNecessery'
import {sumBy} from 'lodash'
import {useCart, useStripeSetupIntent, useTravelers, useUnitCounters} from 'providers/OrderIntentProvider/hooks'
import {appearance} from './paymentElementsAppearance'

export const usePageManager = () => {
  const setupIntent = useStripeSetupIntent()
  const stripePromise = loadStripe(setupIntent.publicApiKey)
  const cart = useCart()
  const travelers = useTravelers()
  const unitCounters = useUnitCounters()
  const dietaryRestrictionsNecessity = useDietaryRestrictionsNecessity()

  const cartIsEmpty = cart.items.length === 0

  const travelersInfoIsIncomplete =
    travelers.length < sumBy(unitCounters, 'quantity') ||
    (dietaryRestrictionsNecessity && travelers.some((traveler) => !traveler.dietaryRestrictions))

  const stripeOptions: StripeElementsOptions = {
    clientSecret: setupIntent.clientSecret,
    locale: 'en',
    appearance,
    fonts: [
      {cssSrc: 'https://fonts.googleapis.com/css2?family=Inter'},
      {cssSrc: 'https://fonts.googleapis.com/css2?family=Inter:wght@400'},
    ],
    loader: 'never',
  }

  const data = {
    stripePromise,
    stripeOptions,
    cartIsEmpty,
    travelersInfoIsIncomplete,
  }

  return {
    data,
  }
}
