import cx from 'classnames'
import {Text} from 'components/Text'
import {getTailwindColor} from 'getTailwindColor'
import {ReactComponent as TimerIcon} from 'icons/timer.svg'
import moment from 'moment'
import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

type Props = {
  utcExpiresAt: string
  className?: string
}

export const Timer = ({utcExpiresAt, className}: Props) => {
  const navigate = useNavigate()
  const expiredMs = new Date(utcExpiresAt).getTime()

  const [restTime, setRestTime] = useState<number | null>(null)

  useEffect(() => {
    const intervalId = setInterval(() => {
      const nowMs = Date.now()
      const newRestTime = expiredMs - nowMs
      setRestTime(newRestTime > 0 ? newRestTime : 0)

      if (newRestTime <= 0) {
        clearInterval(intervalId)
        navigate('/timeout')
      }
    }, 1000)

    return () => {
      clearInterval(intervalId)
    }
  }, [expiredMs, navigate])

  if (restTime === null) {
    return null
  }

  return (
    <div className={cx('flex items-center gap-[10px]', className)}>
      <Text color={restTime < 2 * 60 * 1000 ? getTailwindColor('brand-red') : undefined}>
        {moment(restTime).format('mm[m:]ss[s left]')}
      </Text>
      <TimerIcon />
    </div>
  )
}
