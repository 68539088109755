import {Basic} from 'commonTypes'

export const getTourByCode = (tours: Basic.ExtendedTour[], tourCode: string) => {
  const tour = tours.find((tour) => tour.code === tourCode)

  if (tour) {
    return tour
  }

  throw new Error(`Can't find tour with code '${tourCode}'`)
}
