import cx from 'classnames'
import {ReactNode, forwardRef} from 'react'
import {Left} from './Left'
import {Right} from './Right'

type Props = {
  children?: ReactNode
  className?: string
}

export const StickyFooter = ({children, className}: Props) => {
  return (
    <div
      className={cx(
        'z-[1000] sticky bottom-0 left-0 flex flex-col-reverse md:flex-row justify-between gap-6 py-4 px-4 sm:px-6 bg-white border-t w-full lg:rounded-b-md',
        className
      )}
    >
      {children}
    </div>
  )
}

StickyFooter.SingleButton = forwardRef<HTMLDivElement, Props>(({children, className}, ref) => {
  return (
    <div
      className={cx(
        'z-[1000] sticky bottom-0 left-0 flex flex-col-reverse md:flex-row justify-between gap-6 py-4 px-4 sm:px-6 bg-white border-t w-full lg:rounded-b-md',
        className
      )}
    >
      <Left ref={ref}>{children}</Left>
      <Right className="hidden md:block" />
    </div>
  )
})
