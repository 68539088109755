import cx from 'classnames'
import {Text} from 'components/Text'
import {ReactNode} from 'react'

type Props = {
  children: ReactNode
  className?: string
  title?: string
}

export const Main = ({title, children, className}: Props) => {
  return (
    <div className={cx('w-full p-4 sm:p-6 flex flex-col gap-6 grow', className)}>
      {title && <Text.H3>{title}</Text.H3>}
      {children}
    </div>
  )
}

Main.Simple = ({children, ...other}: Props) => {
  return (
    <Main {...other}>
      <div className="w-full flex flex-col">{children}</div>
    </Main>
  )
}

Main.TwoColumn = ({children, ...other}: Props) => {
  return (
    <Main {...other}>
      <div className="w-full flex gap-8 md:gap-6 flex-col-reverse md:flex-row justify-between">{children}</div>
    </Main>
  )
}
