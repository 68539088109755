import {Entities} from '@exp1/ventrata-utils'
import {Basic} from 'commonTypes'
import {Text} from 'components/Text'
import {capitalize} from 'lodash'
import {AgeRestrictions} from './AgeRestrictions'
import {Counter} from './Counter'
import { formatMoney } from '@exp1/common-utils'
import { useSettings } from 'providers/AppStateProvider/hooks'

type Props = {
  unit: Basic.Unit
  unitCounter: Entities.TypeUnitCounter
  onPlus: () => void
  onMinus: () => void
  initialQuantity: number
  unitPrice: number
}

export const UnitCounter = ({unit, unitCounter, onPlus, onMinus, initialQuantity, unitPrice}: Props) => {
  const {currency} = useSettings()

  return (
    <div className="flex justify-between items-center">
      <div className="flex-col">
        <Text.Bold>{capitalize(unit.type)}</Text.Bold>
        <AgeRestrictions minAge={unit.restrictions.minAge} maxAge={unit.restrictions.maxAge} />
        {/*{unit.description && <Text>{unit.description}</Text>}*/}
      </div>
      <div className="flex gap-6 items-center">
        <Text.Bold>{unitPrice > 0 ? formatMoney(unitPrice, currency) : 'Free'}</Text.Bold>
        <Counter
          initialQuantity={initialQuantity}
          onPlus={onPlus}
          onMinus={onMinus}
          currentQuantity={unitCounter.quantity}
        />
      </div>
    </div>
  )
}
