import cx from 'classnames'
import {Right} from 'components/Right'
import React from 'react'
import {ExpandButton} from '../ExpandButton'
import {CartHeader} from './CartHeader'
import {CartItems} from './CartItems'
import {Pricing} from './Pricing'

type Props = {
  className?: string
}

const DesktopOrderDetails = ({className}: Props) => {
  return (
    <div className={className}>
      <div className="rounded-lg divide-y divide-[#D3D3D3] bg-cart-background flex flex-col h-min">
        <CartHeader collapsable={false} />
        <CartItems />
        <Pricing />
      </div>
    </div>
  )
}

const CartOrderDetails = ({className}: Props) => {
  const {expanded, toggle} = ExpandButton.useExpandButton(false)
  return (
    <div className={className}>
      <div className={cx('rounded-lg bg-cart-background flex flex-col h-min', {'divide-y divide-[#D3D3D3]': expanded})}>
        <CartHeader collapsable={true} expanded={expanded} toggle={toggle} />
        {expanded && (
          <>
            <CartItems />
            <Pricing />
          </>
        )}
      </div>
    </div>
  )
}

export const OrderDetails = () => {
  return (
    <Right>
      <CartOrderDetails className="block sm:hidden" />
      <DesktopOrderDetails className="hidden sm:block" />
    </Right>
  )
}
