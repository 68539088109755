import {ReactNode} from 'react'
import {Link as RouterLink} from 'react-router-dom'
import {LinkText} from './LinkText'

type Clickable = {
  type: 'clickable'
  onClick: () => void
}

type External = {
  type: 'external'
  href: string
}

type Internal = {
  type: 'internal'
  to: string
}

type Kind = Clickable | External | Internal

type Props = Kind & {
  children: string | ReactNode
  disabled?: boolean
  fontSize?: string
  lineHeight?: string
}

export const Link = (props: Props) => {
  if (props.type === 'external') {
    return (
      <a target="_blank" rel="noreferrer" href={props.href}>
        {typeof props.children === 'string' ? (
          <LinkText lineHeight={props.lineHeight} fontSize={props.fontSize}>
            {props.children}
          </LinkText>
        ) : (
          props.children
        )}
      </a>
    )
  }

  if (props.type === 'internal') {
    return (
      <RouterLink to={props.to} className="inline">
        {typeof props.children === 'string' ? (
          <LinkText lineHeight={props.lineHeight} fontSize={props.fontSize}>
            {props.children}
          </LinkText>
        ) : (
          props.children
        )}
      </RouterLink>
    )
  }

  return (
    <span onClick={props.onClick}>
      {typeof props.children === 'string' ? (
        <LinkText lineHeight={props.lineHeight} fontSize={props.fontSize}>
          {props.children}
        </LinkText>
      ) : (
        props.children
      )}
    </span>
  )
}
