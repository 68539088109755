import {ReactNode} from 'react'
import {getTailwindColor} from '../getTailwindColor'

type Props = {
  fontSize?: string
  fontWeight?: 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900
  tag?: string
  lineHeight?: string
  color?: string | null
  children: ReactNode
  className?: string
  onClick?: () => void
}

export const Text = ({
  fontSize = '15px',
  fontWeight = 400,
  tag = 'div',
  lineHeight = '24px',
  color = getTailwindColor('french-blue'),
  className,
  onClick,
  children,
}: Props) => {
  const style = {
    fontSize,
    lineHeight,
    fontWeight,
    color: color === null ? undefined : color,
  }

  const Component = tag as keyof JSX.IntrinsicElements

  return (
    <Component onClick={onClick} style={style} className={className}>
      {children}
    </Component>
  )
}

Text.Bold = ({children, fontWeight = 700, ...other}: Props) => {
  return (
    <Text fontWeight={fontWeight} {...other}>
      {children}
    </Text>
  )
}

Text.H4 = ({fontWeight = 700, fontSize = '20px', lineHeight = '24px', children, ...other}: Props) => {
  return (
    <Text fontWeight={fontWeight} fontSize={fontSize} lineHeight={lineHeight} {...other}>
      {children}
    </Text>
  )
}

Text.H3 = ({fontWeight = 700, fontSize = '26px', lineHeight = '32px', children, ...other}: Props) => {
  return (
    <Text fontWeight={fontWeight} fontSize={fontSize} lineHeight={lineHeight} {...other}>
      {children}
    </Text>
  )
}

Text.H2 = ({fontWeight = 800, fontSize = '32px', lineHeight = '32px', children, ...other}: Props) => {
  return (
    <Text fontWeight={fontWeight} fontSize={fontSize} lineHeight={lineHeight} {...other}>
      {children}
    </Text>
  )
}

Text.Span = ({children, tag = 'span', ...other}: Props) => {
  return (
    <Text {...other} tag={tag}>
      {children}
    </Text>
  )
}
