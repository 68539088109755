import {useApi} from 'api'
import {AxiosError} from 'axios'
import {Params, Responses} from 'commonTypes'
import {useQuery} from 'react-query'
import {QueryOptions} from 'types'

export const useGetResultQuery = ({orderIntentId}: Params.GetResult, options?: QueryOptions) => {
  const api = useApi()
  return useQuery<Responses.GetResult, AxiosError>(['getResult', orderIntentId], () => api.getResult({orderIntentId}), {
    cacheTime: 0,
    ...options,
  })
}
