import cx from 'classnames'
import {Text} from 'components/Text'
import {useLocation, useNavigate} from 'react-router-dom'

type Props = {
  pathname: string
  title: string
  active: boolean | (() => boolean)
}

export const Step = ({pathname, title, active}: Props) => {
  const location = useLocation()
  const navigate = useNavigate()
  const isActive = typeof active === 'boolean' ? active : active()
  const isCurrent = pathname === location.pathname

  return (
    <Text.Bold
      onClick={() => navigate(pathname)}
      color={null}
      className={cx('whitespace-nowrap', {
        'text-neutral-grey-dark': !isActive && !isCurrent,
        'text-brooklyn-parrots-green cursor-pointer hover:text-dark-blue': isActive && !isCurrent,
        'pointer-events-none': isCurrent || !isActive,
      })}
    >
      {title}
    </Text.Bold>
  )
}
