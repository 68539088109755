import {Delimiter} from 'components/Delimiter'
import {Frame} from 'components/Frame'
import {MandatoryPaymentCard} from 'components/MandatoryPaymentCard'
import {TourDetails} from 'components/TourDetails'
import {useTour, useTourMandatoryPayments} from 'providers/AppStateProvider/hooks'
import {getTourImageSrc} from 'utils/getTourImageSrc'

type Props = {
  tourCode: string
}

export const StandardTourCard = ({tourCode}: Props) => {
  const tour = useTour(tourCode)
  const tourMandatoryPayments = useTourMandatoryPayments(tourCode)

  return (
    <Frame imageSrc={getTourImageSrc(tour.images.cover)} imageAlt={tour.product.name} className="p-6">
      <TourDetails tourCode={tourCode} largeMainName showTags showDuration onlyOption />
      {tourMandatoryPayments.length > 0 && (
        <>
          <Delimiter className="my-4" />
          {tourMandatoryPayments.map((mandatoryPayment) => {
            return <MandatoryPaymentCard key={mandatoryPayment.code} mandatoryPayment={mandatoryPayment} />
          })}
        </>
      )}
    </Frame>
  )
}
