import {Button} from 'components/Button'
import {Left} from 'components/Left'
import {Link} from 'components/Link'
import {Main} from 'components/Main'
import {StickyFooter} from 'components/StickyFooter'
import {Text} from 'components/Text'
import {useSettings} from 'providers/AppStateProvider/hooks'
import {useInput} from 'providers/InputProvider'

const changeParentWindowLocation = (siteUrl: string) => {
  if (!window.top) {
    throw new Error(`window.top is undefined`)
  }
  window.top.window.location.href = siteUrl
}

export const Timeout = () => {
  const {siteUrl} = useInput()
  const {templates} = useSettings()
  return (
    <div className="flex flex-col h-full">
      <Main.TwoColumn title="Time expired">
        <Left>
          <Text>Your booking time has expired.</Text>
          <Text.Span>
            But don't worry! You can pick back up with your original tour by clicking the link below or explore our{' '}
          </Text.Span>
          <Link type="external" href={templates['moreTours']}>
            other tours in Paris
          </Link>
          <Text.Span>.</Text.Span>
        </Left>
      </Main.TwoColumn>
      <StickyFooter.SingleButton>
        <Button.Standard text="Start New Order" onClick={() => changeParentWindowLocation(siteUrl)} expand />
      </StickyFooter.SingleButton>
    </div>
  )
}
