import cx from 'classnames'
import codes from 'country-calling-code'
import styles from './styles.module.css'
import {COUNTRY_HASH, parsePhone, stringifyPhone} from './utils'

type Props = {
  value: string
  onChange: (event: {target: {value: string}}) => void
  className?: string
}

export const PhoneInput = ({value, onChange, className}: Props) => {
  const {phoneCode, countryCode, phoneNumber} = parsePhone(value)

  return (
    <div
      className={cx(
        'flex items-center bg-input-background border border-input-border text-french-blue text-base leading-6 rounded placeholder:text-input-placeholder placeholder:text-base placeholder:leading-6',
        className
      )}
    >
      <select
        className={cx('bg-input-background outline-none', styles.select)}
        value={countryCode}
        onChange={(event) => {
          const newCountryCode = event.target.value
          const newPhoneCode = COUNTRY_HASH[newCountryCode].phoneCode
          onChange({target: {value: stringifyPhone(newPhoneCode, newCountryCode, phoneNumber)}})
        }}
      >
        {codes.map(({isoCode2: countryCode}) => {
          const country = COUNTRY_HASH[countryCode]
          return (
            <option key={countryCode} value={countryCode}>
              {`${country.flagIcon} ${country.countryName}`}
            </option>
          )
        })}
      </select>
      <div className="w-24 p-2.5">+{phoneCode}</div>
      <input
        className="outline-none bg-[#F6F5F5] w-full p-2.5"
        type="text"
        value={phoneNumber}
        onChange={(event) => {
          const newPhoneNumber = event.target.value
          onChange({target: {value: stringifyPhone(phoneCode, countryCode, newPhoneNumber)}})
        }}
        autoComplete="tel-national"
      />
    </div>
  )
}
