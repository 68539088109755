import cx from 'classnames'
import {Text} from 'components/Text'
import {FC} from 'react'
import * as React from 'react'

type Props = {
  icon: FC<React.SVGProps<SVGSVGElement>>
  onClick?: () => void
  disabled?: boolean
  className?: string
  title?: string
}

export const IconButton = ({icon: Icon, onClick, disabled = false, className, title}: Props) => {
  return (
    <div
      className={cx(
        'flex gap-2 items-center cursor-pointer hover:opacity-80',
        {'pointer-events-none opacity-50': disabled},
        className
      )}
      onClick={onClick}
    >
      <Icon />
      {title && <Text color={null}>{title}</Text>}
    </div>
  )
}
