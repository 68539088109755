import {formatMoney} from '@exp1/common-utils'
import cx from 'classnames'
import {Basic} from 'commonTypes'
import {Text} from 'components/Text'
import {getTailwindColor} from 'getTailwindColor'
import {useSettings} from 'providers/AppStateProvider/hooks'

type Props = {
  priceDifferences: Basic.UnitPricing
  className?: string
}

export const PriceDifferences = ({priceDifferences, className}: Props) => {
  const {currency} = useSettings()
  return (
    <div className={cx('grid grid-cols-2 gap-y-1 gap-x-2', className)}>
      <Text color={getTailwindColor('grey-0')}>{formatMoney(priceDifferences.ADULT, currency)}/adult</Text>
      <Text color={getTailwindColor('grey-0')}>{formatMoney(priceDifferences.CHILD, currency)}/child</Text>
      <Text color={getTailwindColor('grey-0')}>{formatMoney(priceDifferences.INFANT, currency)}/infant</Text>
    </div>
  )
}
