export type CalendarMonth = {
  year: number
  month: number
}

export type CalendarDate = CalendarMonth & {
  date: number
}

export const DOW = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
