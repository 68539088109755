import cx from 'classnames'
import {ReactComponent as CloseIcon} from 'icons/close.svg'

type Props = {
  className?: string
  onClick: () => void
}

export const CloseButton = ({className, onClick}: Props) => {
  return <CloseIcon className={cx('hover:opacity-50 cursor-pointer', className)} onClick={onClick} />
}
