import {Entities} from '@exp1/ventrata-utils'
import {Text} from 'components/Text'
import {Availability} from './Availability'

type Props = {
  availabilities: Entities.Availability[] | null
  selectedAvailabilityId: string | null
  onChange: (chosenAvailabilityId: string) => void
}

export const Availabilities = ({availabilities, selectedAvailabilityId, onChange}: Props) => {
  if (!availabilities) {
    return null
  }

  return (
    <>
      {availabilities.length === 0 && <Text>No availability</Text>}
      {availabilities.length > 0 && (
        <div className="grid grid-cols-2 gap-y-4">
          {availabilities.map((availability) => {
            return (
              <Availability
                key={availability.id}
                availability={availability}
                selected={selectedAvailabilityId === availability.id}
                onSelect={onChange}
              />
            )
          })}
        </div>
      )}
    </>
  )
}
