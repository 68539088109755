import cx from 'classnames'
import {SmallTourImage} from 'components/SmallTourImage'
import {TourDetails} from 'components/TourDetails'
import {useTour} from 'providers/AppStateProvider/hooks'

type Props = {
  tourCode: string
  className?: string
}

export const SmallTourCard = ({tourCode, className}: Props) => {
  const tour = useTour(tourCode)
  return (
    <div className={cx('flex gap-4', className)}>
      <SmallTourImage tourImage={tour.images.logo} className="shrink-0" />
      <TourDetails tourCode={tourCode} largeMainName onlyOption showTags showDuration />
    </div>
  )
}
