import {Button} from 'components/Button'
import {ErrorMessage} from 'components/ErrorMessage'
import {Left} from 'components/Left'
import {Link} from 'components/Link'
import {Main} from 'components/Main'
import {OrderDetails} from 'components/OrderDetails'
import {StickyFooter} from 'components/StickyFooter'
import {Text} from 'components/Text'
import {range} from 'lodash'
import {CheckoutGTMProvider} from 'providers/WidgetGTMProvider'
import {useRef} from 'react'
import {createPortal} from 'react-dom'
import {Form} from 'react-final-form'
import {Instruction} from './Instruction'
import {Traveler} from './Traveler'
import {usePageManager} from './usePageManager'

export const Travelers = () => {
  const manager = usePageManager()
  const modal = Instruction.useModal()
  const footerRef = useRef<HTMLDivElement>(null)

  return (
    <CheckoutGTMProvider stepName="Step 2 Passes">
      <>
        <Main.TwoColumn>
          <Left className="flex flex-col gap-4" title="Enter pass details">
            <Form
              keepDirtyOnReinitialize
              validate={manager.validate}
              initialValues={manager.data.initialValues}
              onSubmit={manager.save}
              render={({handleSubmit, invalid}) => {
                return (
                  <form id="travelers" className="flex flex-col" onSubmit={handleSubmit}>
                    <div className="flex flex-col gap-8">
                      {range(manager.data.guestQuantity).map((index) => (
                        <Traveler key={index} index={index} />
                      ))}
                    </div>
                    <div className="flex flex-col gap-4 mt-8">
                      <Text>
                        For faster check-in at the attraction, please enter your pass number. You can find it under the
                        QR code on your pass from Go City. Each guest has a unique number.
                      </Text>
                      <Text>Entering your pass number will not activate your pass.</Text>
                      <div>
                        <Link type="clickable" onClick={() => modal.open()}>
                          How to find your pass number
                        </Link>
                        <Instruction state={modal.state} onClose={modal.close} />
                      </div>
                    </div>
                    {footerRef &&
                      footerRef.current &&
                      createPortal(
                        <Button.Standard
                          form="travelers"
                          text={manager.isEdit ? 'Save' : 'Next'}
                          type="submit"
                          disabled={invalid}
                          expand
                        />,
                        footerRef.current
                      )}
                  </form>
                )
              }}
            />
            <ErrorMessage error={manager.error} />
          </Left>
          <OrderDetails />
        </Main.TwoColumn>
        <StickyFooter.SingleButton ref={footerRef} />
      </>
    </CheckoutGTMProvider>
  )
}
