import {Appearance} from '@stripe/stripe-js'
import {getTailwindColor} from '../../getTailwindColor'

export const appearance: Appearance = {
  labels: 'above',
  rules: {
    '.Input': {
      backgroundColor: getTailwindColor('input-background'),
      lineHeight: '24px',
      fontFamily: 'Inter',
      border: `1px solid ${getTailwindColor('input-border')}`,
      boxShadow: 'none',
      borderRadius: '4px',
      padding: '12px 8px',
      color: getTailwindColor('french-blue'),
      // marginBottom: '4px',
      outline: 'none',
      transition: 'none',
      colorTextPlaceholder: getTailwindColor('input-placeholder'),
    },

    '.Input:focus': {
      boxShadow: 'none',
      borderColor: getTailwindColor('input-border'),
    },

    '.Input--invalid': {
      color: '#06273F',
      borderColor: '#F10030',
      borderWidth: '2px',
      boxShadow: 'none',
    },

    '.Label': {
      color: getTailwindColor('french-blue'),
      fontWeight: '400',
      fontFamily: 'Inter',
      fontSize: '15px',
      lineHeight: '24px',
    },

    '.Label--invalid': {
      color: '#F10030',
    },

    '.Error': {
      color: getTailwindColor('brand-red'),
      fontSize: '12px',
      marginTop: '6px',
      marginLeft: '26px',
    },
  },
}
