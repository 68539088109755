import cx from 'classnames'
import {Availabilities} from 'components/Availabilities'
import {Button} from 'components/Button'
import {MonthCalendar} from 'components/Calendars'
import {ErrorMessage} from 'components/ErrorMessage'
import {ExpandButton} from 'components/ExpandButton'
import {Text} from 'components/Text'
import {SmallTourCard} from 'components/TourCards/SmallTourCard'
import {useLocation} from 'react-router-dom'
import {Card} from '../types'
import {useCardManager} from './useCardManager'

type Props = Card & {
  hideExpandButton?: boolean
}

export const BookingCard = (props: Props) => {
  const location = useLocation()
  const manager = useCardManager(props)
  const {tourCode, booked, expanded, hideExpandButton} = props
  const expandButton = ExpandButton.useExpandButton(hideExpandButton ? true : expanded, [location.search])

  return (
    <div className="flex flex-col rounded-lg divide-y divide-neutral-grey border border-neutral-grey">
      <div
        className={cx('p-4 flex justify-between items-center gap-4', {'cursor-pointer': !hideExpandButton})}
        onClick={hideExpandButton ? undefined : () => expandButton.toggle()}
      >
        <SmallTourCard tourCode={tourCode} />
        {!hideExpandButton && <ExpandButton expanded={expandButton.expanded} className="w-6 h-6" />}
      </div>
      {expandButton.expanded && manager.data.days && (
        <>
          <div className="flex flex-col gap-4 px-6 pb-6 pt-4">
            <Text.H4>Select date</Text.H4>
            <MonthCalendar
              tourDate={manager.state.tourDate}
              calendarMonth={manager.state.calendarMonth}
              onChangeTourDate={manager.changeTourDate}
              onChangeCalendarMonth={manager.changeCalendarMonth}
              days={manager.data.days}
            />
          </div>
          {manager.data.availabilities && (
            <div className="flex flex-col gap-4 px-6 pb-6 pt-4">
              <Text.H4>Select time</Text.H4>
              <Availabilities
                availabilities={manager.data.availabilities}
                selectedAvailabilityId={manager.state.availabilityId}
                onChange={manager.changeAvailabilityId}
              />
            </div>
          )}
          <div className="flex flex-col relative">
            <ErrorMessage error={manager.error} className="p-4" />
            <Button.Standard
              text={booked ? 'Save' : 'Next'}
              onClick={manager.save}
              disabled={!manager.isCompleted()}
              className="mb-[-1px] mx-[-1px] !w-[calc(100%+2px)] rounded-t-none"
              expand
            />
          </div>
        </>
      )}
    </div>
  )
}
