import {Entities} from '@exp1/ventrata-utils'
import cx from 'classnames'
import moment from 'moment'
import {Text} from '../Text'
import {CalendarDate} from './types'

type Props = {
  day: Entities.CalendarDay
  calendarDate: CalendarDate | null
  onChangeCalendarDate: (date: CalendarDate) => void
}

export const CalendarDay = ({day, calendarDate, onChangeCalendarDate}: Props) => {
  const momentLocalDate = moment(day.localDate)
  const date = momentLocalDate.date()
  const month = momentLocalDate.month()
  const year = momentLocalDate.year()
  const isAvailable = day.available
  const isSoldOut = day.status === 'SOLD_OUT'
  const isCurrent = calendarDate !== null && date === calendarDate.date
  const isSelectable = isAvailable && !isCurrent

  return (
    <div className="relative flex items-center justify-center">
      <div
        className={cx('absolute w-14 h-14 rounded-full', {
          'bg-explorer-orange': isCurrent,
        })}
      />
      <Text
        fontSize="19px"
        lineHeight="32px"
        key={day.localDate}
        className={cx('relative', {
          'opacity-50': !isAvailable,
          'pointer-events-none': !isSelectable,
          'cursor-pointer': isSelectable,
          'text-white': isCurrent,
          'text-brand-red line-through': isSoldOut,
        })}
        color={null}
        onClick={() => onChangeCalendarDate({year, month, date})}
      >
        {date}
      </Text>
    </div>
  )
}
