import {Basic} from 'commonTypes'
import {find} from 'lodash'
import {useContext} from 'react'
import {getTourByCode} from 'utils/getTourByCode'
import {AppContext} from './provider'
import {AppState} from './types'

export const useAppContext = (): AppState => {
  const context = useContext(AppContext)

  if (!context) {
    throw new Error(`App state context is null`)
  }

  return context
}

const useAppStateData = (property: keyof AppState, throwErrorIfUndefined = true) => {
  const data = useAppContext()[property]

  if (data === undefined && throwErrorIfUndefined) {
    throw new Error(`App state property '${property}' is not defined`)
  }

  return data
}

export const useSettings = () => {
  return useAppStateData('settings') as Basic.Settings
}

export const useTours = () => {
  const {usedTours} = useSettings()
  return usedTours
}

export const useManadtoryPayments = () => {
  const {usedMandatoryPayments} = useSettings()
  return usedMandatoryPayments
}

export const useManadtoryPaymentMatching = () => {
  const {usedMandatoryPaymentMatching} = useSettings()
  return usedMandatoryPaymentMatching
}

const getMandatoryPayment = (mandatoryPayments: Basic.MandatoryPayment[], code: string) => {
  const mandatoryPayment = find(mandatoryPayments, {code})

  if (!mandatoryPayment) {
    throw new Error(`Can't find mandatory payment code '${code}'`)
  }

  return mandatoryPayment
}

const getTourMandatoryPayments = (
  usedMandatoryPayments: Basic.MandatoryPayment[], 
  usedMandatoryPaymentMatching: Basic.MandatoryPaymentMatchingItem[], 
  tourCode: string
) => {
  const mandatoryPaymentMatchingItem = find(usedMandatoryPaymentMatching, {tourCode})

  if (!mandatoryPaymentMatchingItem) {
    return []
  }

  const tourMandatoryPayments = []

  for (const mandatoryPaymentCode of mandatoryPaymentMatchingItem.mandatoryPaymentCodes) {
    const mandatoryPayment = getMandatoryPayment(usedMandatoryPayments, mandatoryPaymentCode)
    tourMandatoryPayments.push(mandatoryPayment)
  }

  return tourMandatoryPayments
}

export const useTourMandatoryPayments = (tourCode: string) => {
  const usedMandatoryPayments = useManadtoryPayments()
  const usedMandatoryPaymentMatching = useManadtoryPaymentMatching()
  return getTourMandatoryPayments(usedMandatoryPayments, usedMandatoryPaymentMatching, tourCode)
}

export const useToursMandatoryPayments = (tourCodes: string[]) => {
  const usedMandatoryPayments = useManadtoryPayments()
  const usedMandatoryPaymentMatching = useManadtoryPaymentMatching()

  const result = []

  for (const tourCode of tourCodes) {
    const tourMandatoryPayment = getTourMandatoryPayments(usedMandatoryPayments, usedMandatoryPaymentMatching, tourCode)
    result.push(...tourMandatoryPayment)
  }

  return result
}

export const useMandatoryPayment = (code: string) => {
  const usedMandatoryPayments = useManadtoryPayments()
  return getMandatoryPayment(usedMandatoryPayments, code)
}

export const useTour = (tourCode: string) => {
  const tours = useTours()
  return getTourByCode(tours, tourCode)
}

export const useUnits = () => {
  return useAppStateData('units') as Basic.Unit[]
}

export const useOptionalSettings = () => {
  return useAppStateData('settings', false) as Basic.Settings | undefined
}

export const useOptionalTours = () => {
  const settings = useOptionalSettings()

  if (!settings) {
    return settings
  }

  return settings.usedTours
}

export const useOptionalUnits = () => {
  return useAppStateData('units', false) as Basic.Unit[] | undefined
}
