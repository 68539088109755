import cx from 'classnames'
import {Text} from 'components/Text'
import {TourTags} from 'components/TourTags'
import {ReactComponent as TimerIcon} from 'icons/timer.svg'
import {useTour} from 'providers/AppStateProvider/hooks'

type Props = {
  tourCode: string
  showTags?: boolean
  showDuration?: boolean
  largeMainName?: boolean
  className?: string
  onlyOption?: boolean
  description?: string
}

export const TourDetails = ({
  tourCode,
  showTags,
  showDuration,
  largeMainName,
  onlyOption,
  className,
  description,
}: Props) => {
  const tour = useTour(tourCode)
  const mainName = onlyOption ? tour.option.name : tour.product.name

  return (
    <div className={cx('flex flex-col', className)}>
      {largeMainName ? <Text.H4>{mainName}</Text.H4> : <Text.Bold>{mainName}</Text.Bold>}
      {tour.option.name !== mainName && <Text>{tour.option.name}</Text>}
      {description && (
        <Text>
          <span dangerouslySetInnerHTML={{__html: description}} />
        </Text>
      )}
      {(showDuration || showTags) && (
        <div className="flex gap-4 items-center">
          {showDuration && (
            <div className="flex gap-2 items-center">
              <TimerIcon height={16} width={16} />
              <Text>{tour.option.duration}</Text>
            </div>
          )}
          {showTags && <TourTags tourCode={tourCode} />}
        </div>
      )}
    </div>
  )
}
