import cx from 'classnames'
import {noop} from 'lodash'
import styles from './styles.module.css'

type Props = {
  checked?: boolean
  disabled?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  className?: string
  name?: string
  value?: string
  id?: string
}

export const RadioInput = ({id, checked, disabled, onChange = noop, className, name, value}: Props) => {
  return (
    <input
      id={id}
      name={name}
      value={value}
      type="radio"
      className={cx(styles.radio, className)}
      checked={checked}
      disabled={disabled}
      onChange={onChange}
    />
  )
}
