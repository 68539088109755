import axios from 'axios'
import {useInput} from 'providers/InputProvider'
import {useMemo} from 'react'
import {Params, Responses} from './commonTypes'

export const useApi = () => {
  const {siteUrl} = useInput()

  return useMemo(() => {
    const axiosInstance = axios.create({
      headers: {
        Referer: siteUrl,
      },
    })

    const getTourAddons = async (params: Params.GetTourAddons): Promise<Responses.GetTourAddons> => {
      const result = await axiosInstance.post<Responses.GetTourAddons>(`/api/getTourAddons`, params)
      return result.data
    }

    const getTourUpgrades = async (params: Params.GetTourUpgrades): Promise<Responses.GetTourUpgrades> => {
      const result = await axiosInstance.post<Responses.GetTourUpgrades>(`/api/getTourUpgrades`, params)
      return result.data
    }

    const getAllAllowedUpgrades = async (
      params: Params.GetAllAllowedUpgrades
    ): Promise<Responses.GetAllAllowedUpgrades> => {
      const result = await axiosInstance.post<Responses.GetAllAllowedUpgrades>(`/api/getAllAllowedUpgrades`, params)
      return result.data
    }

    const getUnits = async (params: Params.GetUnits): Promise<Responses.GetUnits> => {
      const result = await axiosInstance.post<Responses.GetUnits>(`/api/getUnits`, params)
      return result.data
    }

    const getMonthCalendar = async (params: Params.GetMonthCalendar): Promise<Responses.GetCalendar> => {
      const result = await axiosInstance.post<Responses.GetCalendar>(`/api/getMonthCalendar`, params)
      return result.data
    }

    const getWeekCalendar = async (params: Params.GetWeekCalendar): Promise<Responses.GetCalendar> => {
      const result = await axiosInstance.post<Responses.GetCalendar>(`/api/getWeekCalendar`, params)
      return result.data
    }

    const getAvailabilities = async (params: Params.GetAvailabilities): Promise<Responses.GetAvailabilities> => {
      const result = await axiosInstance.post<Responses.GetAvailabilities>(`/api/getAvailabilities`, params)
      return result.data
    }

    const getResult = async (params: Params.GetResult): Promise<Responses.GetResult> => {
      const result = await axiosInstance.post<Responses.GetResult>('/api/getResult', params)
      return result.data
    }

    const getOrderIntent = async (params: Params.GetOrderIntent): Promise<Responses.OrderIntent> => {
      const result = await axiosInstance.post<Responses.OrderIntent>('/api/getOrderIntent', params)
      return result.data
    }

    const confirmOrder = async (params: Params.ConfirmOrder): Promise<Responses.OrderIntent> => {
      const result = await axiosInstance.post<Responses.OrderIntent>('/api/confirmOrder', params)
      return result.data
    }

    const getSettings = async (params: Params.GetSettings): Promise<Responses.GetSettings> => {
      const result = await axiosInstance.post<Responses.GetSettings>('/api/getSettings', params)
      return result.data
    }

    const addCartItem = async (params: Params.AddCartItem): Promise<Responses.OrderIntentWithAffectedCartItem> => {
      const result = await axiosInstance.post<Responses.OrderIntentWithAffectedCartItem>('/api/addCartItem', params)
      return result.data
    }

    const removeCartItem = async (
      params: Params.RemoveCartItem
    ): Promise<Responses.OrderIntentWithAffectedCartItem> => {
      const result = await axiosInstance.post<Responses.OrderIntentWithAffectedCartItem>('/api/removeCartItem', params)
      return result.data
    }

    const setCartItemAvailabilityId = async (
      params: Params.SetCartItemAvailabilityId
    ): Promise<Responses.OrderIntentWithAffectedCartItem> => {
      const result = await axiosInstance.post<Responses.OrderIntentWithAffectedCartItem>(
        '/api/setCartItemAvailabilityId',
        params
      )
      return result.data
    }

    const upgradeCartItem = async (
      params: Params.UpgradeCartItem
    ): Promise<Responses.OrderIntentWithAffectedCartItem> => {
      const result = await axiosInstance.post<Responses.OrderIntentWithAffectedCartItem>('/api/upgradeCartItem', params)
      return result.data
    }

    const resetCartItem = async (params: Params.ResetCartItem): Promise<Responses.OrderIntentWithAffectedCartItem> => {
      const result = await axiosInstance.post<Responses.OrderIntentWithAffectedCartItem>('/api/resetCartItem', params)
      return result.data
    }

    const createOrderIntent = async (params: Params.CreateOrderIntent): Promise<Responses.OrderIntent> => {
      const result = await axiosInstance.post<Responses.OrderIntent>('/api/createOrderIntent', params)
      return result.data
    }

    const setContact = async (params: Params.SetContact): Promise<Responses.OrderIntent> => {
      const result = await axiosInstance.post<Responses.OrderIntent>('/api/setContact', params)
      return result.data
    }

    const setUnitCounters = async (params: Params.SetUnitCounters): Promise<Responses.OrderIntent> => {
      const result = await axiosInstance.post<Responses.OrderIntent>('/api/setUnitCounters', params)
      return result.data
    }

    const setTravelers = async (params: Params.SetTravelers): Promise<Responses.OrderIntent> => {
      const result = await axiosInstance.post<Responses.OrderIntent>('/api/setTravelers', params)
      return result.data
    }

    return {
      getTourAddons,
      getTourUpgrades,
      getUnits,
      getMonthCalendar,
      getWeekCalendar,
      getAvailabilities,
      confirmOrder,
      getResult,
      getSettings,
      addCartItem,
      removeCartItem,
      setCartItemAvailabilityId,
      setContact,
      createOrderIntent,
      setUnitCounters,
      upgradeCartItem,
      resetCartItem,
      setTravelers,
      getAllAllowedUpgrades,
      getOrderIntent,
    }
  }, [siteUrl])
}
