import {Basic} from 'commonTypes'
import {OrderIntent} from 'commonTypes/basic'
import {ErrorMessage} from 'components/ErrorMessage'
import {Main} from 'components/Main'
import {useConfirmOrderQuery} from 'hooks/queries/useConfirmOrderQuery'
import {useCreateOrderIntentQuery} from 'hooks/queries/useCreateOrderIntentQuery'
import {useGetOrderIntentQuery} from 'hooks/queries/useGetOrderIntentQuery'
import {useInput} from 'providers/InputProvider'
import {useLastActionAtSetter, useOrderIntentInfo, useOrderIntentInfoSetter} from 'providers/LocalStorageProvider/hooks'
import React, {useCallback, useMemo, useState} from 'react'
import {Navigate, useLocation} from 'react-router-dom'
import {OrderIntentValue} from './types'

type Props = {
  children: React.ReactNode
}

export const OrderIntentContext = React.createContext<OrderIntentValue | null>(null)

export const OrderIntentProvider = ({children}: Props) => {
  const location = useLocation()
  const {orderIntentId: passedOrderIntentId} = useInput()
  const savedOrderIntentId = useOrderIntentInfo()?.id
  const {configCode} = useInput()
  const [state, setState] = useState<OrderIntent | null>(null)
  const setOrderIntentInfo = useOrderIntentInfoSetter()
  const setLastActionsAt = useLastActionAtSetter()

  const needToCreate = Boolean(!state && !savedOrderIntentId && !passedOrderIntentId)
  const needToConfirm = Boolean(!state && passedOrderIntentId)
  const needToLoad = Boolean(!state && savedOrderIntentId && !passedOrderIntentId)

  const getTitle = useCallback(() => {
    if (needToCreate) {
      return ''
    }

    if (needToConfirm) {
      return 'Order confirmation...'
    }

    if (needToLoad) {
      return 'Order loading...'
    }
  }, [needToCreate, needToConfirm, needToLoad])

  const getOrderIntentQuery = useGetOrderIntentQuery(
    {orderIntentId: savedOrderIntentId || 'NO SAVED ORDER INTENT ID'},
    {
      enabled: needToLoad,
      onSuccess: ({orderIntent}) => setState(orderIntent),
    }
  )

  const createOrderIntentQuery = useCreateOrderIntentQuery(
    {configCode},
    {
      enabled: needToCreate,
      onSuccess: ({orderIntent}) => setState(orderIntent),
    }
  )

  const confirmOrderQuery = useConfirmOrderQuery(
    {orderIntentId: passedOrderIntentId || 'NO PASSED ORDER INTENT ID'},
    {
      enabled: needToConfirm,
      onSuccess: ({orderIntent}) => setState(orderIntent),
    }
  )

  const setOrderIntent = useCallback(
    (orderIntent: Basic.OrderIntent) => {
      setState(orderIntent)
      setOrderIntentInfo(orderIntent)
      setLastActionsAt()
    },
    [setLastActionsAt, setOrderIntentInfo]
  )

  const orderIntentValue = useMemo(() => {
    return {
      orderIntent: state,
      setOrderIntent,
    }
  }, [state, setOrderIntent])

  const error = getOrderIntentQuery.error || createOrderIntentQuery.error || confirmOrderQuery.error

  if (!orderIntentValue.orderIntent) {
    return (
      <Main.Simple title={getTitle()}>
        <ErrorMessage error={error} />
      </Main.Simple>
    )
  }

  if (orderIntentValue.orderIntent.payment && !location.pathname.startsWith('/result')) {
    return <Navigate to="/result" />
  }

  return <OrderIntentContext.Provider value={orderIntentValue}>{children}</OrderIntentContext.Provider>
}
