import {AVAILABILITY_STATUSES_UNION, Entities} from '@exp1/ventrata-utils'
import cx from 'classnames'
import {Text} from 'components/Text'
import {RadioInput} from 'components/inputs/RadioInput'
import {getTailwindColor} from 'getTailwindColor'
import moment from 'moment'

type Props = {
  availability: Entities.Availability
  selected: boolean
  onSelect: (availabilityId: string) => void
}

const getStatusText = (status: AVAILABILITY_STATUSES_UNION) => {
  switch (status) {
    case 'AVAILABLE':
      return 'Available'
    case 'CLOSED':
      return 'Closed'
    case 'LIMITED':
      return 'Limited'
    case 'FREESALE':
      return 'Freesale'
    case 'SOLD_OUT':
      return 'Sold Out'
    default:
      return 'Unknown'
  }
}

const getStatusColor = (status: AVAILABILITY_STATUSES_UNION) => {
  switch (status) {
    case 'CLOSED':
      return 'neutral-grey-dark'
    case 'SOLD_OUT':
      return 'brand-red'
    default:
      return 'french-blue'
  }
}

export const Availability = ({availability, selected, onSelect}: Props) => {
  const onClick = () => {
    if (availability.available) {
      onSelect(availability.id)
    }
  }

  if (!availability.status) {
    throw new Error('Availability status is not defined')
  }

  return (
    <div className={cx('flex gap-2', {'cursor-pointer': availability.available})} onClick={onClick}>
      <RadioInput checked={selected} disabled={!availability.available} />
      <div className="flex flex-col">
        <Text color={getTailwindColor(availability.available ? 'french-blue' : 'neutral-grey-dark')}>
          {moment.parseZone(availability.id).format('hh:mm A')}
        </Text>
        {!availability.available && (
          <Text color={getTailwindColor(getStatusColor(availability.status))} fontSize="13px" lineHeight="16px">
            {getStatusText(availability.status)}
          </Text>
        )}
      </div>
    </div>
  )
}
