import {useAddCartItemMutation} from 'hooks/mutations/useAddCartItemMutation'
import {useGetAvailabilitiesQuery} from 'hooks/queries/useGetAvailabilitiesQuery'
import {useGetWeekCalendarQuery} from 'hooks/queries/useGetWeekCalendarQuery'
import moment from 'moment'
import {useUnits} from 'providers/AppStateProvider/hooks'
import {useInput} from 'providers/InputProvider'
import {useCart, useOrderIntent, useOrderIntentSetter, useUnitCounters} from 'providers/OrderIntentProvider/hooks'
import {useChangeCartEventSender} from 'providers/WidgetGTMProvider'
import {momentToCalendarDate} from 'utils/momentToCalendarDate'
import {useCardStateManager} from './useCardStateManager'

type Params = {
  tourCode: string
}

const useFirstCartItem = () => {
  const cart = useCart()

  if (!cart || cart.items.length === 0) {
    throw new Error('Cant retrieve first cart item')
  }

  return cart.items[0]
}

const getInitialCalendarWeek = (availabilityId: string) => {
  const startOfWeek = moment.parseZone(availabilityId).startOf('isoWeek')
  return momentToCalendarDate(startOfWeek)
}

export const useCardManager = ({tourCode}: Params) => {
  const firstCartItem = useFirstCartItem()

  const initialState = {
    availabilityId: null,
    tourDate: null,
    calendarWeek: getInitialCalendarWeek(firstCartItem.availabilityId),
  }
  const {configCode} = useInput()
  const units = useUnits()
  const orderIntent = useOrderIntent()
  const unitCounters = useUnitCounters()
  const manager = useCardStateManager(initialState)
  const setOrderIntent = useOrderIntentSetter()
  const sendChangeCartEvent = useChangeCartEventSender()
  const addCartItemMutation = useAddCartItemMutation()

  const save = () => {
    if (!manager.state.availabilityId) {
      throw new Error('Availability is null')
    }

    addCartItemMutation.mutate(
      {
        availabilityId: manager.state.availabilityId,
        tourCode,
        orderIntentId: orderIntent.id,
      },
      {
        async onSuccess({orderIntent, affectedCartItem}) {
          await sendChangeCartEvent(affectedCartItem, 'add')
          setOrderIntent(orderIntent)
        },
      }
    )
  }

  const getCalendarQuery = useGetWeekCalendarQuery(
    {
      configCode,
      tourCode,
      unitCounters,
      calendarWeek: manager.state.calendarWeek,
    },
    {
      keepPreviousData: true,
    }
  )

  const getAvailabilitiesQuery = useGetAvailabilitiesQuery(
    {
      configCode,
      tourCode,
      unitCounters,
      tourDate: manager.state.tourDate,
    },
    {
      keepPreviousData: true,
    }
  )

  const error = getCalendarQuery.error || getAvailabilitiesQuery.error || addCartItemMutation.error

  const availabilities = getAvailabilitiesQuery.data?.availabilities || null
  const days = getCalendarQuery.data?.calendar

  const data = {
    state: manager.state,
    availabilities,
    days,
    units,
  }

  return {
    state: manager.state,
    error,
    data,
    save,
    changeTourDate: manager.changeTourDate,
    changeCalendarWeek: manager.changeCalendarWeek,
    changeAvailabilityId: manager.changeAvailabilityId,
    isCompleted: manager.isCompleted,
  }
}
