import {formatMoney} from '@exp1/common-utils'
import {IconButton} from 'components/IconButton'
import {Text} from 'components/Text'
import {TourDetails} from 'components/TourDetails'
import {useResetCartItemMutation} from 'hooks/mutations/useResetCartItemMutation'
import {ReactComponent as EditIcon} from 'icons/edit.svg'
import {ReactComponent as LightningIcon} from 'icons/lightning.svg'
import {ReactComponent as MinusIcon} from 'icons/remove.svg'
import {useSettings, useTour} from 'providers/AppStateProvider/hooks'
import {useOrderIntentId, useOrderIntentSetter} from 'providers/OrderIntentProvider/hooks'
import {useChangeCartEventSender} from 'providers/WidgetGTMProvider'
import {useNavigate} from 'react-router-dom'
import {RemoveConfirmation} from './RemoveConfirmation'

type Props = {
  tourCode: string
  amount: number
  cartItemId: string
}

export const UpgradedTour = ({tourCode, amount, cartItemId}: Props) => {
  const {currency} = useSettings()
  const navigate = useNavigate()
  const orderIntentId = useOrderIntentId()
  const setOrderIntent = useOrderIntentSetter()
  const cancelTourUpgrade = useResetCartItemMutation()
  const modal = RemoveConfirmation.useModal()
  const tour = useTour(tourCode)
  const sendChangeCartEvent = useChangeCartEventSender()

  const edit = () => navigate(`/steps/upgrades?cart_item_id=${cartItemId}`)

  const removeUpgrade = () => {
    cancelTourUpgrade.mutate(
      {
        orderIntentId,
        cartItemId,
      },
      {
        async onSuccess({orderIntent, affectedCartItem}) {
          await sendChangeCartEvent(affectedCartItem, 'remove')
          setOrderIntent(orderIntent)
          navigate(`/steps/upgrades?cart_item_id=${cartItemId}`)
        },
      }
    )
  }

  return (
    <div className="flex flex-col">
      <div className="flex gap-4">
        <div className="w-16 h-16 shrink-0" />
        {/*<img src={tour.images.logo} alt={tour.product.name} className="w-16 h-16 rounded-lg" />*/}
        <div className="flex flex-col">
          <div className="flex tracking-[.04em]">
            <div className="flex gap-2 items-center">
              <LightningIcon />
              <Text fontSize="13px" lineHeight="16px">
                ADD-ON
              </Text>
            </div>
            <Text fontSize="13px" lineHeight="16px" className="whitespace-pre">
              {' '}
              •{' '}
            </Text>
            <Text fontSize="13px" lineHeight="16px">
              {formatMoney(amount, currency, true)}
            </Text>
          </div>
          <TourDetails tourCode={tourCode} onlyOption />
          <div className="flex gap-6 h-8">
            <IconButton icon={EditIcon} onClick={edit} title="Edit" />
            <IconButton icon={MinusIcon} onClick={() => modal.open(tour)} title="Delete" className="text-brand-red" />
          </div>
        </div>
      </div>
      <div className="relative top-4">
        <RemoveConfirmation state={modal.state} onClose={modal.close} onRemove={removeUpgrade} />
      </div>
    </div>
  )
}
