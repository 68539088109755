import {Text} from './Text'

type Props = {
  title: string
  htmlFor?: string
  required?: boolean
  className?: string
}

export const Label = ({className, title, htmlFor, required = false}: Props) => {
  return (
    <div className={className}>
      <label htmlFor={htmlFor}>
        <Text tag="span">{title}</Text>
      </label>
      {required && <Text tag="span">*</Text>}
    </div>
  )
}
