import {Stripe, StripeElements} from '@stripe/stripe-js'
import {useMutation} from 'react-query'

type Params = {
  stripe: Stripe
  elements: StripeElements
  siteUrl: string
  orderIntentId: string
  tourCode: string
  configCode: string
}

export const useConfirmSetupMutation = () => {
  return useMutation(async ({stripe, elements, siteUrl, tourCode, configCode, orderIntentId}: Params) => {
    const redirectQuery = new URLSearchParams({
      site_url: siteUrl,
      order_intent_id: orderIntentId,
      tour_code: tourCode,
      config_code: configCode,
    }).toString()
    const redirectUrl = encodeURIComponent('/result?' + redirectQuery)
    const returnUrl = siteUrl + '?redirect_url=' + redirectUrl

    const {error} = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: returnUrl,
      },
    })

    if (error) {
      throw error
    }
  })
}
