import {useGetTourAddonsQuery} from 'hooks/queries/useGetTourAddonsQuery'
import {useCart, useOrderIntentId} from 'providers/OrderIntentProvider/hooks'

export const usePageManager = () => {
  const orderIntentId = useOrderIntentId()
  const cart = useCart()
  const getTourAddonsQuery = useGetTourAddonsQuery({orderIntentId})

  const addonTourCodesLoaded = getTourAddonsQuery.data !== undefined
  const addonTourCodes = addonTourCodesLoaded ? getTourAddonsQuery.data.tourCodes : []

  const allowedAddonTourCodes = addonTourCodes.filter(
    (tourCode) => !cart.items.some((cartItem) => cartItem.original.tour.code === tourCode)
  )

  const error = getTourAddonsQuery.error

  const data = {
    allowedAddonTourCodes,
    addonTourCodesLoaded,
  }

  return {
    error,
    data,
  }
}
