import {Entities, UNIT_TYPES_UNION} from '@exp1/ventrata-utils'
import {Basic} from 'commonTypes'
import {Frame} from 'components/Frame'
import {Text} from 'components/Text'
import {UnitCounter} from './UnitCounter'

type Props = {
  units: Basic.Unit[] | null
  unitCounters: Entities.TypeUnitCounter[] | null
  onPlus: (type: UNIT_TYPES_UNION) => void
  onMinus: (type: UNIT_TYPES_UNION) => void
  pricing: Basic.UnitPricing
}

export const UnitCounters = ({units, unitCounters, onPlus, onMinus, pricing}: Props) => {
  if (units === null || unitCounters === null) {
    return null
  }

  return (
    <Frame className="p-6 flex flex-col gap-4">
      <Text.H4>Guests</Text.H4>
      {units.map((unit, i) => {
        const unitCounter = unitCounters.find((item) => item.type === unit.type)

        if (!unitCounter) {
          throw new Error(`Can't find unit counter by type '${unit.type}'`)
        }

        const unitPrice = pricing[unit.type]

        return (
          <UnitCounter
            key={unit.type}
            unit={unit}
            unitCounter={unitCounter}
            onPlus={() => onPlus(unit.type)}
            onMinus={() => onMinus(unit.type)}
            initialQuantity={i === 0 ? 1 : 0}
            unitPrice={unitPrice}
          />
        )
      })}
    </Frame>
  )
}
