import cx from 'classnames'
import {ReactNode, forwardRef} from 'react'
import {Text} from './Text'

type Props = {
  children?: ReactNode
  className?: string
  title?: string
}

export const Left = forwardRef<HTMLDivElement, Props>(({children, className, title}, ref) => {
  return (
    <div className={cx('w-full md:w-left-side', className)} ref={ref}>
      {title && <Text.H3>{title}</Text.H3>}
      {children}
    </div>
  )
})
