import cx from 'classnames'
import {ReactNode} from 'react'

type Props = {
  className?: string
  children: ReactNode
  onClick?: () => void
}

export const OrderComponent = ({children, className, onClick}: Props) => {
  return (
    <div className={cx('p-4', className)} onClick={onClick}>
      {children}
    </div>
  )
}
