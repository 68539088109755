import {sortBy} from 'lodash'
import {useOptionalCart} from 'providers/OrderIntentProvider/hooks'
import {CartItem} from './CartItem'
import {OrderComponent} from './OrderComponent'

export const CartItems = () => {
  const cart = useOptionalCart()

  if (!cart) {
    return null
  }

  const sortedCartItems = sortBy(cart.items, 'availabilityId')

  return (
    <OrderComponent className="divide-y divide-[#D3D3D3] py-0">
      {sortedCartItems.map((cartItem) => (
        <CartItem key={cartItem.id} cartItem={cartItem} />
      ))}
    </OrderComponent>
  )
}
