import {Basic} from 'commonTypes'
import {Button} from 'components/Button'
import {Left} from 'components/Left'
import {Main} from 'components/Main'
import {OrderDetails} from 'components/OrderDetails'
import {StickyFooter} from 'components/StickyFooter'
import {Text} from 'components/Text'
import {useSettings} from 'providers/AppStateProvider/hooks'
import {CheckoutGTMProvider} from 'providers/WidgetGTMProvider'
import {useState} from 'react'
import {Navigate, useNavigate} from 'react-router'
import {TourAddonCard} from './TourAddonCard'
import {usePageManager} from './usePageManager'

const LIMIT = 3

type Props = {
  tourCodes: string[]
}

const AddonsList = ({tourCodes}: Props) => {
  const [collapsed, setCollapsed] = useState(true)

  if (tourCodes.length <= LIMIT || !collapsed) {
    return (
      <>
        {tourCodes.map((tourCode) => {
          return <TourAddonCard key={tourCode} tourCode={tourCode} />
        })}
      </>
    )
  }

  const tourCodesOfVisibleAddons = tourCodes.slice(0, LIMIT)
  const tourCodeOfBorderAddon = tourCodes[LIMIT]
  return (
    <>
      {tourCodesOfVisibleAddons.map((tourCode) => {
        return <TourAddonCard key={tourCode} tourCode={tourCode} />
      })}
      <div className="overflow-y-hidden h-[256px] relative">
        <TourAddonCard tourCode={tourCodeOfBorderAddon} />
        <div className="absolute w-full h-full top-0 left-0 bg-gradient-to-t from-white" />
      </div>
      <Button.Standard text="Show more tours" onClick={() => setCollapsed(false)} expand outline />
    </>
  )
}

const getNextPath = (settings: Basic.Settings) => {
  return settings.thereAreTourUpgrades ? '/steps/upgrades' : '/steps/contact'
}

export const TourAddons = () => {
  const settings = useSettings()
  const navigate = useNavigate()
  const manager = usePageManager()
  const {allowedAddonTourCodes, addonTourCodesLoaded} = manager.data

  const nextPath = getNextPath(settings)

  return (
    <CheckoutGTMProvider stepName="Step 4 More tours">
      <>
        <Main.TwoColumn>
          <Left
            className="flex flex-col gap-4"
            title={addonTourCodesLoaded ? 'Explore more tours with your pass' : 'Searching more tours...'}
          >
            {addonTourCodesLoaded && allowedAddonTourCodes.length > 0 && (
              <>
                <Text fontSize="19px" lineHeight="32px">
                  Get the most out of your pass with more tours — included free!
                </Text>
                <AddonsList tourCodes={allowedAddonTourCodes} />
              </>
            )}
            {addonTourCodesLoaded && allowedAddonTourCodes.length === 0 && <Navigate to={nextPath} />}
          </Left>
          <OrderDetails />
        </Main.TwoColumn>
        {addonTourCodesLoaded && allowedAddonTourCodes.length > 0 && (
          <StickyFooter.SingleButton>
            <Button.Standard text="Next" onClick={() => navigate(nextPath)} expand />
          </StickyFooter.SingleButton>
        )}
      </>
    </CheckoutGTMProvider>
  )
}
