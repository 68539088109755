import {pluralize} from '@exp1/common-utils'
import {Entities} from '@exp1/ventrata-utils'
import {capitalize} from 'lodash'
import {Text} from '../Text'

type Props = {
  unitCounter: Entities.TypeUnitCounter
}

export const CartUnit = ({unitCounter}: Props) => {
  return (
    <div className="flex justify-between">
      <Text className="whitespace-pre">{unitCounter.quantity} </Text>
      <Text>{capitalize(pluralize(unitCounter.quantity, unitCounter.type))}</Text>
    </div>
  )
}
