import {Location} from '@remix-run/router'
import {Responses} from 'commonTypes'
import {useGetAllAllowedUpgradesQuery} from 'hooks/queries/useGetAllAllowedUpgradesQuery'
import {reduce} from 'lodash'
import {useCart, useOrderIntentId} from 'providers/OrderIntentProvider/hooks'
import {useHistoryStepPathOrCurrent, useIsReturnStepVisit} from 'providers/StepHistoryProvider/hooks'
import {useLocation, useNavigate} from 'react-router-dom'

const parseLocation = (location: Location) => {
  const params = new URLSearchParams(location.search)
  const cartItemId = params.get('cart_item_id')
  return {cartItemId}
}

export const usePageManager = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const {cartItemId} = parseLocation(location)
  const isReturnStepVisit = useIsReturnStepVisit()
  const penultimateStepPath = useHistoryStepPathOrCurrent(-2)
  const orderIntentId = useOrderIntentId()
  const cartItems = useCart().items
  const getAllAllowedUpgradeQuery = useGetAllAllowedUpgradesQuery({orderIntentId})

  const error = getAllAllowedUpgradeQuery.error

  const countUpgrades = (data: Responses.GetAllAllowedUpgrades) => {
    return reduce(
      data.upgrades,
      (result, upgrades) => {
        return result + upgrades.length
      },
      0
    )
  }

  const upgradesIsLoaded = getAllAllowedUpgradeQuery.data
  const hasAllowedUpgrades = getAllAllowedUpgradeQuery.data && countUpgrades(getAllAllowedUpgradeQuery.data) > 0
  const hasNotAllowedUpgrades = getAllAllowedUpgradeQuery.data && countUpgrades(getAllAllowedUpgradeQuery.data) === 0
  const allowedUpgrades = getAllAllowedUpgradeQuery.data ? getAllAllowedUpgradeQuery.data.upgrades : {}

  const getNextButtonCaption = () => {
    if (isReturnStepVisit) {
      return 'Save'
    }

    return cartItems.some((cartItem) => cartItem.upgraded) ? 'Next' : 'Skip'
  }

  const data = {
    cartItems,
    allowedUpgrades,
    hasAllowedUpgrades,
    hasNotAllowedUpgrades,
    upgradesIsLoaded,
    editedCartItemId: cartItemId,
    nextButtonCaption: getNextButtonCaption(),
  }

  const save = () => {
    isReturnStepVisit ? navigate(penultimateStepPath) : navigate('/steps/contact')
  }

  return {
    error,
    data,
    isEdit: isReturnStepVisit,
    save,
  }
}
