import {useApi} from 'api'
import {AxiosError} from 'axios'
import {Params, Responses} from 'commonTypes'
import {useQuery} from 'react-query'
import {QueryOptions} from 'types'

export const useCreateOrderIntentQuery = ({configCode}: Params.CreateOrderIntent, options?: QueryOptions) => {
  const api = useApi()
  return useQuery<Responses.OrderIntent, AxiosError>(
    ['createOrderIntent', configCode],
    () => api.createOrderIntent({configCode}),
    {
      ...options,
    }
  )
}
