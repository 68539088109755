import React from 'react'
import {Text} from '../Text'

type Props = {
  minAge?: number
  maxAge?: number
}

const getRestriction = (minAge?: number, maxAge?: number) => {
  return maxAge === 100 ? `${minAge}+` : `${minAge}-${maxAge}`
}

export const AgeRestrictions = ({minAge, maxAge}: Props) => {
  const text = getRestriction(minAge, maxAge)
  return <Text>{text}</Text>
}
