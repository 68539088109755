import {Entities} from '@exp1/ventrata-utils'
import {useApi} from 'api'
import {CalendarDate} from 'components/Calendars/types'
import {useQuery} from 'react-query'
import {QueryOptions} from 'types'

type Params = {
  configCode: string
  tourCode: string
  unitCounters: Entities.TypeUnitCounter[] | null
  calendarWeek: CalendarDate
}

export const useGetWeekCalendarQuery = (
  {unitCounters, tourCode, configCode, calendarWeek}: Params,
  options?: QueryOptions
) => {
  const api = useApi()
  return useQuery(
    ['getWeekCalendar', configCode, tourCode, unitCounters, calendarWeek],
    () => {
      if (unitCounters === null) {
        throw new Error('Unit counters is null')
      }

      return api.getWeekCalendar({
        month: calendarWeek.month,
        year: calendarWeek.year,
        date: calendarWeek.date,
        unitCounters,
        tourCode,
        configCode,
      })
    },
    {
      cacheTime: 0,
      enabled: unitCounters !== null,
      ...options,
    }
  )
}
