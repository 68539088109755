import cx from 'classnames'
import {TourImage} from 'commonTypes/basic'
import parse from 'style-to-js'

type Props = {
  tourImage: TourImage
  className?: string
}

export const SmallTourImage = ({tourImage, className}: Props) => {
  const src = typeof tourImage === 'string' ? tourImage : tourImage.src
  const cssStyles = typeof tourImage === 'string' ? '' : tourImage.styles || ''
  const jsStyles = {
    backgroundImage: `url(${src})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '64px',
    ...parse(cssStyles),
  }

  return <div style={jsStyles} className={cx('w-16 h-16 rounded-lg', className)} />
}
