import {Entities} from '@exp1/ventrata-utils'
import {Text} from 'components/Text'
import moment from 'moment'
import {CalendarDay} from './CalendarDay'
import {CalendarNavigation} from './CalendarNavigation'
import {CalendarDate} from './types'

type Props = {
  tourDate: CalendarDate | null
  calendarWeek: CalendarDate
  onChangeTourDate: (date: CalendarDate | null) => void
  onChangeCalendarWeek: (month: CalendarDate) => void
  days: Entities.CalendarDay[] | null
  className?: string
}

const incWeek = (current: CalendarDate): CalendarDate => {
  const date = moment(current).add(7, 'days')
  return {year: date.year(), month: date.month(), date: date.date()}
}

const decWeek = (current: CalendarDate): CalendarDate => {
  const date = moment(current).subtract(7, 'days')
  return {year: date.year(), month: date.month(), date: date.date()}
}

const getMonthTitle = (calendarWeek: CalendarDate) => {
  const startWeekMonth = calendarWeek.month
  const endWeekMonth = moment(calendarWeek).add(6, 'days').month()

  if (startWeekMonth === endWeekMonth) {
    return moment.months(startWeekMonth)
  }
  return `${moment.months(startWeekMonth)} - ${moment.months(endWeekMonth)}`
}

export const WeekCalendar = ({
  tourDate,
  calendarWeek,
  days,
  onChangeTourDate,
  onChangeCalendarWeek,
  className,
}: Props) => {
  if (!days) {
    return null
  }

  const changeCalendarDate = (date: CalendarDate) => {
    onChangeTourDate(date)
  }

  const changeCalendarWeek = (date: CalendarDate) => {
    onChangeCalendarWeek(date)
  }

  return (
    <div className={className}>
      <div className="flex items-center justify-between mt-2">
        <Text.Bold>
          {getMonthTitle(calendarWeek)} {calendarWeek.year}
        </Text.Bold>
        <div className="flex items-center gap-3">
          <CalendarNavigation onClick={() => changeCalendarWeek(decWeek(calendarWeek))} direction="left" />
          <CalendarNavigation onClick={() => changeCalendarWeek(incWeek(calendarWeek))} direction="right" />
        </div>
      </div>
      <div className="grid grid-cols-[repeat(7,min-content)] justify-between gap-y-2 justify-items-center mt-3">
        {days.map((day) => {
          const DOW = moment.parseZone(day.localDate).format('ddd')
          return <Text.Bold key={DOW}>{DOW}</Text.Bold>
        })}
        {days.map((day) => (
          <CalendarDay
            key={day.localDate}
            day={day}
            calendarDate={tourDate}
            onChangeCalendarDate={changeCalendarDate}
          />
        ))}
      </div>
    </div>
  )
}
