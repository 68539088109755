import {Elements} from '@stripe/react-stripe-js'
import {Button} from 'components/Button'
import {Left} from 'components/Left'
import {Main} from 'components/Main'
import {OrderDetails} from 'components/OrderDetails'
import {StickyFooter} from 'components/StickyFooter'
import {Text} from 'components/Text'
import {CheckoutGTMProvider} from 'providers/WidgetGTMProvider'
import {useRef} from 'react'
import {useNavigate} from 'react-router-dom'
import {CheckoutForm} from './CheckoutForm'
import {usePageManager} from './usePageManager'

export const Checkout = () => {
  const manager = usePageManager()
  const navigate = useNavigate()
  const footerRef = useRef<HTMLDivElement>(null)

  return (
    <CheckoutGTMProvider stepName="Step 7 Payment">
      <>
        <Main.TwoColumn>
          <Left title="Complete payment details" className="flex flex-col gap-4">
            {manager.data.cartIsEmpty && (
              <>
                <Text.H4 className="mb-2">Your cart is empty</Text.H4>
                <Text className="mb-4">Please add at least one tour to your cart before making payment.</Text>
              </>
            )}
            {manager.data.travelersInfoIsIncomplete && (
              <>
                <Text.H4 className="mb-2">Your travelers info is incomplete</Text.H4>
                <Text className="mb-4">Please provide some additional details about travelers.</Text>
                <Button.Standard text="Complete travelers info" onClick={() => navigate('/steps/travelers')} expand />
              </>
            )}
            {!manager.data.cartIsEmpty && !manager.data.travelersInfoIsIncomplete && (
              <Elements stripe={manager.data.stripePromise} options={manager.data.stripeOptions}>
                <CheckoutForm footerRef={footerRef} />
              </Elements>
            )}
          </Left>
          <OrderDetails />
        </Main.TwoColumn>
        <StickyFooter.SingleButton ref={footerRef} />
      </>
    </CheckoutGTMProvider>
  )
}
