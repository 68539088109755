import {FC, useCallback, useState} from 'react'

type Closed = {
  opened: false
}

type Opened<Payload> = {
  opened: true
  payload: Payload
}

export type ModalState<Payload> = Closed | Opened<Payload>

type Props<Payload> = {
  state: ModalState<Payload>
  content: FC<{className?: string; payload: Payload}>
  onClose: () => void
  className?: string
}

export const Modal = <Payload,>({state, onClose, content: Content}: Props<Payload>) => {
  const rootElement = document.getElementById('root')

  if (!rootElement) {
    throw new Error('Root element is null')
  }

  if (!state.opened) {
    return null
  }

  return (
    <div>
      <div
        className="fixed left-0 top-0 w-screen h-screen bg-backdrop bg-opacity-50 flex justify-center items-center z-10"
        onClick={onClose}
      >
        <div className="p-5 bg-white rounded-lg" onClick={(event) => event.stopPropagation()}>
          <Content payload={state.payload} />
        </div>
      </div>
    </div>
  )
}

Modal.useModal = <Payload,>() => {
  const [state, setState] = useState<ModalState<Payload>>({opened: false})

  const open = useCallback((payload: Payload) => {
    const body = document.getElementById('body')!
    body.classList.add('overflow-y-hidden')
    setState({opened: true, payload})
  }, [])

  const close = useCallback(() => {
    const body = document.getElementById('body')!
    body.classList.remove('overflow-y-hidden')
    setState({opened: false})
  }, [])

  return {
    state,
    open,
    close,
  }
}
