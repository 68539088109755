import {useTour} from 'providers/AppStateProvider/hooks'
import {TourTag} from './TourTag'

type Props = {
  tourCode: string
}

export const TourTags = ({tourCode}: Props) => {
  const tour = useTour(tourCode)

  if (!tour.option.tags || tour.option.tags.length === 0) {
    return null
  }

  return (
    <div className="flex gap-4">
      {tour.option.tags.map((tag) => (
        <TourTag key={tag} tag={tag} />
      ))}
    </div>
  )
}
