import {CalendarDate, CalendarMonth} from 'components/Calendars/types'
import {useReducer} from 'react'
import {Action, State} from '../types'
import {changeAvailabilityId, changeCalendarMonth, changeTourDate} from './actions'

const pageStateReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'changeTourDate':
      return changeTourDate(state, action.tourDate)
    case 'changeCalendarMonth':
      return changeCalendarMonth(state, action.calendarMonth)
    case 'changeAvailabilityId':
      return changeAvailabilityId(state, action.availabilityId)
  }
}

export const useCardStateManager = (initialState: State) => {
  const [state, dispatch] = useReducer(pageStateReducer, initialState)

  const changeAvailabilityId = (availabilityId: string | null) => {
    dispatch({
      type: 'changeAvailabilityId',
      availabilityId,
    })
  }

  const changeTourDate = (tourDate: CalendarDate | null) => {
    dispatch({
      type: 'changeTourDate',
      tourDate,
    })
  }

  const changeCalendarMonth = (calendarMonth: CalendarMonth) => {
    dispatch({
      type: 'changeCalendarMonth',
      calendarMonth,
    })
  }

  const isCompleted = () => {
    return state.availabilityId !== null
  }

  return {
    state,
    changeAvailabilityId,
    changeCalendarMonth,
    changeTourDate,
    isCompleted,
  }
}
